import React from "react";
import { Typography } from "@material-ui/core";

import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import WarningIcon from "@material-ui/icons/Warning";
import Grid from "@material-ui/core/Grid";

import filesize from "filesize";

import { orange600 } from "../../../themes/theme.module.css";

import ModalContentBase from "../../UI/InfoBoard/ModalContentBase";

import Dialog, {
  TT_ORANGE600,
} from "../../UI/Dialogs/DraggableDialog/DraggableDialog";
import { DF_BTN_WARNING } from "../../UI/Dialogs/DialogFooter";

export const FROM_BROWSER_DOWNLOAD = "FROM_BROWSER_DOWNLOAD";
export const FROM_CURL_DOWNLOAD = "FROM_CURL_DOWNLOAD";

const MoreThanOnePageDlg = ({
  data,
  handleDownloadAll,
  handleDownloadPage,
  handleCancel,
}) => {
  const { total_file_count, total_file_size } = data;
  const txtStyle = {
    fontSize: 16,
  };
  return (
    <Dialog
      topType={TT_ORANGE600}
      btnColor={DF_BTN_WARNING}
      acceptBtnFn={handleDownloadAll}
      acceptBtnText="Download ALL"
      cancelBtnFn={handleDownloadPage}
      cancelBtnText="Download page"
    >
      <ModalContentBase
        titleText="Datasets are on multiple pages"
        closeFn={handleCancel}
      >
        <Box style={{ backgroundColor: "#ff9e1b26", padding: 20 }}>
          {/* // rgba(255, 158, 27, 0.15) */}
          <Grid container spacing={1}>
            <Grid item>
              <WarningIcon
                style={{
                  color: orange600,
                  width: 24,
                  height: 24,
                }}
              />
            </Grid>
            <Grid item>
              <Typography paragraph style={txtStyle}>
                There is more than one page with data to download.
              </Typography>
              <Typography paragraph style={txtStyle}>
                You have {total_file_count} files (
                {filesize(total_file_size, { round: 1 })} total) across all
                pages.
              </Typography>
              <Typography paragraph style={txtStyle}>
                You can download All pages or choose to download the current
                page only.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </ModalContentBase>
    </Dialog>
  );
};

export default MoreThanOnePageDlg;

MoreThanOnePageDlg.propTypes = {
  data: PropTypes.shape().isRequired,
  handleDownloadAll: PropTypes.func.isRequired,
  handleDownloadPage: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
