import { useLocation } from "react-router-dom";

export const useSearchParams = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  return {
    its_ap_id: searchParams.get("its_ap_id"),
    its_sp_id: searchParams.get("its_sp_id"),
    projectId: searchParams.get("projectId"), // for backward compatibility
    organism: searchParams.get("organism"),
    pmo_project_id: searchParams.get("pmo_project_id"),
    restoreid: searchParams.get("restoreid"),
    genome_id: searchParams.get("genome_id"),
    cart: searchParams.get("cart"), // Cart page
    cx: searchParams.get("cx"), // Cart page paging size
    cp: searchParams.get("cp"), // Cart page paging number
    q: searchParams.get("q"), // query string
    is_advanced_search: searchParams.get("t") === "advanced", // advanced flag
    is_from_my_data_portal: Boolean(searchParams.get("f")), // search from My Data Portal -> Select & Download Files
    mdm: searchParams.get("mdm"), // My Data Portal page menu
    mdmo: searchParams.get("mdmo"), // My Data Portal page menu's submenu open/close
    jdptest: searchParams.get("jdptest"), // for dev test
    include_private_data: searchParams.get("include_private_data"), // for dev test
    group: searchParams.get("group"), // for dev test
    fregx: searchParams.get("fregx"), // for advanced file regx filter
    psize: searchParams.get("x"), // for advanced file regx filter
    searchParams,
    FILE_NAME_REGX_KEY: "fregx",
  };
};

export { useSearchParams as default };
