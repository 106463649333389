/* istanbul ignore file */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import theme from "../../../themes/theme.module.css";
import classes from "./PulldownMenu.module.css";

const useStyles = makeStyles({
  buttonIcon: {
    "& .MuiButton-outlined": {
      border: "1px solid #F00",
    },
    "& .MuiButton-endIcon": {
      position: "absolute",
      right: 16,
      color: theme.lake500,
    },
  },
});

const PulldownMenu = ({ items, intVal, onSelect, windowWidth }) => {
  const btnStyle = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState();
  const open = Boolean(anchorEl);
  const buttonRef = useRef();
  const [buttonWidth, setButtonWidth] = useState();
  const [lastWindowWidth, setLastWindowWidth] = useState(windowWidth);

  useEffect(() => {
    setValue(intVal);
  }, [intVal]);

  const resizePulldown = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setButtonWidth(rect.width);
    }
  };

  useEffect(() => {
    resizePulldown();
  }, [buttonRef]);

  useEffect(() => {
    if (buttonRef?.current && windowWidth !== lastWindowWidth) {
      resizePulldown();
      setLastWindowWidth(windowWidth);
    }
  }, [windowWidth]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (label) => {
    onSelect(label);
    setValue(label);
    handleClose();
  };

  const entIconStyle = {
    fontSize: 28,
  };
  return (
    <>
      <Button
        ref={buttonRef}
        onClick={handleClick}
        sx={{
          height: 30,
          width: "100%",
          justifyContent: "flex-start",
          textTransform: "none",
          color: value ? theme.grey700 : theme.grey500,
          border: `1px solid ${value ? theme.lake500 : theme.grey500}`,
          ":hover": {
            color: value ? theme.grey700 : theme.grey500,
            border: `1px solid ${theme.lake500}`,
          },
        }}
        endIcon={
          open ? (
            <ExpandLess style={entIconStyle} />
          ) : (
            <ExpandMore style={entIconStyle} />
          )
        }
        className={btnStyle.buttonIcon}
      >
        {value || "Select your choice"}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <List
          component="div"
          disablePadding
          sx={{
            width: buttonWidth || "100%",
            bgcolor: "background.paper",
          }}
          aria-label="contacts"
        >
          {items.map((d, idx) => {
            const key = `${d.label.replace(" ", "_")}`;
            return (
              <React.Fragment key={key}>
                <ListItem
                  className={classes.ItemText}
                  style={{ padding: "4px 20px" }}
                >
                  <Box sx={{ width: "100%" }}>
                    <ListItemText
                      primary={d.label}
                      onClick={() => {
                        handleSelection(d.label);
                      }}
                    />
                  </Box>
                </ListItem>
                {idx < items.length - 1 && (
                  <Divider sx={{ borderBottomWidth: 2 }} />
                )}
              </React.Fragment>
            );
          })}
        </List>
      </Menu>
    </>
  );
};
export default PulldownMenu;

PulldownMenu.defaultProps = {
  onSelect: () => {},
  items: null,
  intVal: "", // initial value
  windowWidth: 600,
};

PulldownMenu.propTypes = {
  onSelect: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape()),
  intVal: PropTypes.string,
  windowWidth: PropTypes.number,
};
