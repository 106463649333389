/* istanbul ignore file */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ButtonMui from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import LinearProgress from "@mui/material/LinearProgress";

import ErrorIcon from "@mui/icons-material/Error";

import { getNativeButton } from "../Button/Button";
import JDPHelpIcon from "../UI/Icons/JDPHelpIcon";
import filterClasses from "../FilterContainer/Filters/Filters.module.css";
import { HREF_DOC_ADVANCED_SEARCH } from "../../utils/URLConstants";
import PulldownMenu from "../UI/PulldownMenu/PulldownMenu";
import { advancedSearchKeys } from "../../utils/HttpClientProvider";
import { useSearchParams } from "../../hooks/useSearchParams";

import theme from "../../themes/theme.module.css";

const ADV_SEARCH_OP = ["AND", "OR", "NOT"];
const ALL_FIELDS = "All Fields";
const ERROR_MSG =
  "We were not able to process your query. Please check the type or the syntax of your query and try again.";

const AdvancedSearch = ({
  isLoading,
  error,
  open,
  onClose,
  honeycomb,
  topRect,
}) => {
  const [searchMeta, setSearchMeta] = useState([{ value: "" }]);
  const [keyMap, setKeyMap] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const history = useHistory();
  const { q } = useSearchParams();

  useEffect(() => {
    const promise = advancedSearchKeys();
    promise.then((resp) => {
      const kMap = {
        desc2name: {
          "All Fields": ALL_FIELDS,
        },
        name2desc: {
          "All Fields": ALL_FIELDS,
        },
      };
      resp?.forEach((d) => {
        const { description, name } = d;
        kMap.desc2name[description] = name;
        kMap.name2desc[name] = description;
      });

      setKeyMap(kMap);
    });

    honeycomb.sendUiInteractionSpan("adv-search-ui-opened");
  }, []);

  useEffect(() => {
    if (error) {
      setErrorMsg(ERROR_MSG);
    } else {
      setErrorMsg(null);
    }
  }, [error]);

  useEffect(() => {
    if (q && JSON.stringify(searchMeta[0]) === '{"value":""}' && keyMap) {
      const initd = {
        key: ALL_FIELDS,
        value: q,
      };
      setSearchMeta([initd]);
    }
  }, [q, keyMap]);

  // style for the 1st row read-only TextField
  const inputSizeStyle = {
    "& .MuiInputBase-input": {
      height: 12,
      padding: 1,
      border: `solid 1px ${theme.grey500}`,
      borderRadius: 1,
      backgroundColor: theme.grey100,
      color: theme.grey700,
    },
  };

  // style for the 1 or more search value TextField
  const inputStyle = {
    "& .MuiInputBase-input": {
      ...inputSizeStyle["& .MuiInputBase-input"],
    },
    color: theme.grey700,
    "& .MuiInputBase-input:hover": {
      border: `solid 1px ${theme.lake500}`,
    },
    "& .MuiInputBase-input:active": {
      border: `solid 1px ${theme.lake500}`,
    },
  };
  delete inputStyle["& .MuiInputBase-input"].backgroundColor;

  const iconStyle = {
    color: theme.lake500,
    cursor: "pointer",
  };

  const updateValue = (index, value, type = "value") => {
    const meta = [...searchMeta];
    if (type === "value") {
      meta[index].value = value;
    } else if (type === "operator") {
      meta[index].operator = value;
    } else {
      // key
      meta[index].key = value;
    }
    setSearchMeta(meta);
  };

  const ICON_ADD = "ICON_ADD";
  const ICON_REMOVE = "ICON_REMOVE";
  const add_remove = (index, type) => {
    const meta = [...searchMeta];
    if (type === ICON_ADD) {
      meta.splice(index + 1, 0, { value: "" });
    } else {
      meta.splice(index, 1);
    }

    setSearchMeta(JSON.parse(JSON.stringify(meta)));
  };

  const learnMoreUI = (
    <JDPHelpIcon size="large">
      <Typography
        className={`${filterClasses.bodySmall} ${filterClasses.filterHelpText}`}
        noWrap
        style={{ position: "relative", top: 6 }}
      >
        <Link
          className={filterClasses.filterHelpLink}
          href={HREF_DOC_ADVANCED_SEARCH}
          target="_blank"
          data-testid="advSearchBuilderHelp"
          onClick={() => {
            honeycomb.sendUiInteractionSpan("adv-search-learn-more-clicked");
          }}
        >
          Learn more
        </Link>{" "}
        about value types and examples
      </Typography>
    </JDPHelpIcon>
  );

  const endColumnStyle = {
    width: searchMeta.length > 1 ? 60 : 30,
    position: "relative",
    left: -18,
  };

  const getSearchParamUI = () => {
    const adv_ops = ADV_SEARCH_OP.map((d) => ({ label: d }));
    return searchMeta.map((d, idx) => {
      const key = `adv-value-ui-${idx}`;
      const { value, operator } = d;
      const valueStyle = { ...inputStyle };
      if (value) {
        const istyle = {
          ...valueStyle["& .MuiInputBase-input"],
          border: `solid 1px ${theme.lake500}`,
        };
        valueStyle["& .MuiInputBase-input"] = istyle;
      }

      return (
        <React.Fragment key={key}>
          <Grid item lg={1} md={2} sm={2} xs={3}>
            {idx > 0 && (
              <Box>
                <PulldownMenu
                  items={adv_ops}
                  intVal={operator || ADV_SEARCH_OP[0]}
                  onSelect={(val) => {
                    updateValue(idx, val, "operator");
                  }}
                  windowWidth={topRect?.width}
                />
              </Box>
            )}
          </Grid>
          <Grid item lg={2} md={3} sm={4} xs={5}>
            <Box>
              {keyMap ? (
                <PulldownMenu
                  intVal={d.key}
                  items={Object.keys(keyMap.desc2name)
                    .sort()
                    .map((desc) => ({ label: desc }))}
                  onSelect={(val) => {
                    updateValue(idx, val, "key");
                  }}
                  windowWidth={topRect?.width}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
          <Grid item lg={8} md={6} sm={5} xs={3}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <TextField
                variant="outlined"
                fullWidth
                disabled={!d.key}
                sx={d.key ? valueStyle : inputStyle}
                value={value}
                onChange={(event) => {
                  updateValue(idx, event.target.value);
                }}
                inputProps={{
                  // make the font size same as the pulldownMene
                  style: {
                    fontSize: 14,
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item md={1} sm={1} xs={1}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box sx={endColumnStyle}>
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    top: 4,
                    marginLeft: 1,
                  }}
                >
                  {searchMeta.length > 1 && (
                    <RemoveCircleOutlineIcon
                      sx={iconStyle}
                      onClick={() => {
                        add_remove(idx, ICON_REMOVE);
                      }}
                    />
                  )}
                  {(searchMeta.length === 1 ||
                    searchMeta.length - 1 === idx) && (
                    <AddCircleOutlineIcon
                      sx={value ? iconStyle : { color: theme.grey500 }}
                      onClick={
                        value
                          ? () => {
                              add_remove(idx, ICON_ADD);
                            }
                          : null
                      }
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </React.Fragment>
      );
    });
  };

  const queryValue = () => {
    let qstring = null;
    searchMeta.forEach((d, idx) => {
      const cleanedValue = d.value ? d.value.trim() : null;
      if (cleanedValue) {
        const value =
          cleanedValue.indexOf(" ") > -1 ? `(${cleanedValue})` : cleanedValue;
        let op = idx > 0 ? d.operator || ADV_SEARCH_OP[0] : "";
        op = op && ` ${op} `;
        const key = keyMap.desc2name[d.key];
        const extKey = key === "All Fields" ? "" : `${key}:`;
        const extValue = key === "All Fields" ? value : `\`${value}\``;
        qstring = qstring ? `(${qstring})` : "";
        qstring += `${op}${extKey}${extValue}`;
      }
    });
    return qstring;
  };

  const doAdvancedQuery = () => {
    const qstr = queryValue();
    const searchParams = new URLSearchParams(); // blank search params
    searchParams.set("q", qstr);
    searchParams.set("t", "advanced");
    const { pathname } = window.location;
    const advString = searchParams.toString(); // use plain text in URL
    const toPath = pathname !== "/" ? `?${advString}` : `/search?${advString}`;
    history.replace(toPath);
    honeycomb.sendUiInteractionSpan("adv-search-performed", {
      search_value: toPath,
    });
  };

  const searchButtonConfig = {
    onClick: doAdvancedQuery,
    variant: "contained",
    color: "primary",
    size: "large",
    className: "button",
    style: { width: 100, height: 34 },
    id: "adv-search-btn-id",
    disabled: searchMeta ? !searchMeta[0].value : true,
    children: "Search",
  };

  const searchButton = getNativeButton({ ...searchButtonConfig });

  return (
    <div>
      <Drawer anchor="top" open={open} onClose={onClose}>
        <Box sx={{ width: "auto", padding: "20px 40px" }} role="presentation">
          <Grid
            container
            sx={{
              width: "auto",
              margin: 0,
              padding: 0,
            }}
            spacing={1}
          >
            {/* row 1 */}
            <Grid item md={11} sm={11} xs={11}>
              <Box sx={{ display: "flex" }}>
                <TextField
                  id="advanced-query-value"
                  variant="outlined"
                  disabled
                  fullWidth
                  sx={inputSizeStyle}
                  value={queryValue() || ""}
                />
              </Box>
            </Grid>
            <Grid item md={1} sm={1} xs={1} />
            {/* end row 1 */}

            {/* middle rows */}
            {getSearchParamUI()}
            {/* end middle rows */}

            {/* last row */}
            <Grid item md={3} sm={3} xs={6}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {searchButton}
                <ButtonMui
                  sx={{
                    textTransform: "none",
                    textDecoration: "underline",
                    marginLeft: 2,
                    color: theme.grey700,
                    fontSize: 14,
                  }}
                  onClick={() => {
                    setSearchMeta(JSON.parse(JSON.stringify([{ value: "" }])));
                    setErrorMsg(null);
                  }}
                >
                  <Typography noWrap>Clear All</Typography>
                </ButtonMui>
              </Box>
            </Grid>
            <Grid item md={9} sm={9} xs={6}>
              <Box sx={{ display: "flex" }}>
                {isLoading ? (
                  <LinearProgress
                    style={{
                      position: "relative",
                      top: 12,
                      height: 10,
                      marginTop: 2,
                      marginRight: 40,
                    }}
                  />
                ) : errorMsg ? (
                  <Box
                    style={{
                      backgroundColor: "#FFE4DE",
                      marginRight: 40,
                      padding: "4px 20px 8px",
                    }}
                  >
                    <ErrorIcon
                      style={{
                        position: "relative",
                        top: 6,
                        color: "#FF0000",
                        marginRight: 4,
                      }}
                    />
                    {errorMsg}
                  </Box>
                ) : null}
                <Box sx={{ marginLeft: "auto", minWidth: 340 }}>
                  {learnMoreUI}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </div>
  );
};

export default AdvancedSearch;

AdvancedSearch.defaultProps = {
  error: null,
  isLoading: false,
  open: false,
  onClose: () => {},
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  topRect: null,
};

AdvancedSearch.propTypes = {
  error: PropTypes.shape(),
  isLoading: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  honeycomb: PropTypes.shape(),
  topRect: PropTypes.shape(),
};
