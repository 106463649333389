import React from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import classes from "./BorderlessButton.module.css";

const BorderlessButton = ({ label, onClick }) => {
  const labelStyle = {
    fontFamily: "Public Sans",
    fontSize: 19,
    fontWeight: 700,
  };
  return (
    <Button
      className={classes.Button}
      sx={{
        textTransform: "none",
        color: classes.lake500,
      }}
      onClick={onClick}
      disableRipple
    >
      <Typography style={labelStyle}>{label}</Typography>
    </Button>
  );
};

export default BorderlessButton;

BorderlessButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
