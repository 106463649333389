import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Box, Typography, AppBar, Paper } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import Downloader from "../Downloader/Downloader";
import GenomeTable from "../GenomeTable/GenomeTable";

import { useFileAndPageContext } from "../../context/FileAndPageContext";

import classes from "./TableContainer.module.css";
import { ShowOnScroll } from "./ScrollTransitions";

import { useSearchParams } from "../../hooks/useSearchParams";

import { dictOfPrimativeEqual } from "../../utils/ObjectUtils";

import PagingUI, { PAGE_SIZE_ARRAY } from "./Pagination/PagingUI";
import NoDraggableDialog from "../UI/InfoBoard/Standalone/NoDraggableDialog";

import { pageTotal } from "../../utils/CartDataTools";
import ReturnLink from "./ReturnLink";

import { useApiCustom } from "../../hooks/useApiCustom";
import { JDP_API_CART_DATA } from "../../utils/HttpClientProvider";

const DEFAULT_PAGE_SIZE = PAGE_SIZE_ARRAY[0];

const CartPage = (props) => {
  const { honeycomb } = props;

  const [cartOrgAndFileIds, setCartOrgAndFileIds] = useState(null);
  const { cartData, clearCartData } = useFileAndPageContext();

  const { searchParams, cp, cx } = useSearchParams();

  const { useGenomeQuery } = useApiCustom(honeycomb);

  // payload=null for empty cart will cause BE error state
  // on the other hand, if useGenomeQuery() return early on payload=null, react will throw out
  // "React has detected a change in the order of Hooks called" error ...

  const payload = cartOrgAndFileIds
    ? { ...cartOrgAndFileIds }
    : {
        ids: ["badid"],
        organisms: ["badid"],
        top_hits: [],
      };

  /* istanbul ignore next */
  if (cartData?.include_private_data) {
    payload.include_private_data = 1;
  }
  const { data, isLoading } = useGenomeQuery({
    customURL: JDP_API_CART_DATA,
    method: "post",
    payload,
    allowNullPayload: false,
  });

  const history = useHistory();

  const defaultPageSpect = {
    size: DEFAULT_PAGE_SIZE,
    page: 1,
    total: 1,
  };

  // cart paging
  const [pageSpec, setPageSpec] = useState(defaultPageSpect);

  const [clearCartDialog, setClearCartDialog] = useState(false);

  /*
    cart page use URLParams cp and cx as the page and pageSize param, to avoid BE page fetch 
    (cart data is from localStorage)
  * */
  /* istanbul ignore next */
  const setCartPageSize = (size) => {
    if (cartData?.organisms) {
      const spec = { ...pageSpec };
      spec.size = size;
      spec.total = cartData?.organisms
        ? pageTotal(size, cartData.organisms.length)
        : 1;
      if (spec.total > 0 && spec.page > spec.total) spec.page = spec.total;

      let urlParamsChanged = false;
      if (size === DEFAULT_PAGE_SIZE) {
        if (searchParams.get("cx")) {
          searchParams.delete("cx");
          urlParamsChanged = true;
        }
      } else if (parseInt(searchParams.get("cx"), 10) !== size) {
        searchParams.set("cx", size);
        urlParamsChanged = true;
      }

      if (searchParams.get("cp")) {
        searchParams.delete("cp");
        urlParamsChanged = true;
      }

      if (!dictOfPrimativeEqual(spec, pageSpec)) {
        setPageSpec(spec);
      }
      if (urlParamsChanged) {
        history.push(`?${searchParams.toString()}`);
      }
    }
  };

  /* istanbul ignore next */
  const setCartPageNumber = (page) => {
    const spec = { ...pageSpec };
    spec.page = page;
    setPageSpec(spec);

    let searchParamsChanged = false;
    if (page === 1) {
      if (searchParams.get("cp")) {
        searchParams.delete("cp");
        searchParamsChanged = true;
      }
    } else if (parseInt(searchParams.get("cp"), 10) !== page) {
      searchParams.set("cp", page);
      searchParamsChanged = true;
    }
    if (searchParamsChanged) {
      history.push(`?${searchParams.toString()}`);
    }
  };

  // TODO : JDP-2271 - when use BE to fetch card data,
  //  if cartData.include_private_data, add include_private_data=1 in endpoint search param
  /* istanbul ignore next */
  useEffect(() => {
    if (cartData?.organisms?.length > 0) {
      const organisms = cartData.organisms?.map((org) => org.id);
      const top_hits = cartData.organisms?.map((org) => org.top_hit_id);
      const ids = cartData.organisms?.reduce(
        (list, org) => list.concat(org.files),
        []
      );

      const spec = { ...pageSpec };
      const page = parseInt(cp, 10);
      const size = parseInt(cx, 10);

      if (Number.isNaN(page) && spec.page !== 1) {
        spec.page = 1;
      } else if (!Number.isNaN(page) && page !== spec.page) {
        spec.page = page;
      }
      if (Number.isNaN(size) && spec.size !== DEFAULT_PAGE_SIZE) {
        spec.size = DEFAULT_PAGE_SIZE;
      } else if (!Number.isNaN(size) && size !== spec.size) {
        spec.size = size;
      }

      // total page counts
      const total = pageTotal(spec.size, cartData.organisms.length);

      if (total !== spec.total) {
        spec.total = total;
        if (total === 0) {
          spec.page = 1;
          spec.size = DEFAULT_PAGE_SIZE;
        }
      }
      if (spec.page > spec.total) {
        // out of ranage, set to 1
        spec.page = 1;
      }
      setPageSpec(spec); // always do this on cartData change, to force page update

      // console.log("[CP] set org and ids", organisms, ids);
      const oAndf =
        organisms?.length > 0 && ids?.length > 0
          ? { organisms, ids, top_hits }
          : null;
      setCartOrgAndFileIds(oAndf);
    } else {
      setCartOrgAndFileIds(null);
    }
  }, [cartData, cx, cp]);

  /* istanbul ignore next */
  const handleReturnToResultsPage = () => {
    if (searchParams.get("cart")) {
      searchParams.delete("cart");
      history.push(`?${searchParams.toString()}`);
    }
  };

  /* istanbul ignore next */
  const handleClearCart = () => {
    honeycomb.sendUiInteractionSpan("cart-data-change", {
      action: "clear all",
      data: { cart: cartData },
    });
    clearCartData();
    setPageSpec(defaultPageSpect);
  };

  const downloader = () => {
    const files = data?.organisms
      ? {
          files: data.organisms.reduce(
            (list, org) => list.concat(org.files),
            []
          ),
          include_private_data: Boolean(cartData?.include_private_data),
        }
      : null;

    return (
      <Downloader files={files} isLoading={isLoading} {...props} scoreMin={0} />
    );
  };

  const downloaderRef = useRef(null);

  return (
    <>
      <Box className={classes.titleBox}>
        <Typography variant="h1">Cart</Typography>
        <ReturnLink
          leftLabel="Return to the Results Page"
          leftClick={handleReturnToResultsPage}
          rightLabel="Clear Cart"
          rightClick={
            cartData?.organisms?.length > 0
              ? () => {
                  setClearCartDialog(true);
                }
              : null
          }
          rightDisable={!cartData?.organisms?.length > 0}
        />
      </Box>
      <>
        {/* JDP-1786: Do not HideOnScroll for this set of combo. 
              Also DO NOT omit -- ref is used by ShowOnScroll set + change height to this combo causes height detection in ShowOnScroll flips outcome
           */}
        <Paper
          className={classes.downloaderBoxInCart}
          elevation={1}
          ref={downloaderRef}
        >
          {downloader()}
        </Paper>
        <div className={classes.searchInfoBox} style={{ marginBottom: 2 }}>
          <div style={{ marginLeft: "auto" }}>
            <PagingUI
              pageTotal={pageSpec.total}
              pageSizeChanged={setCartPageSize}
              pageNumChanged={setCartPageNumber}
              pageSizeValue={pageSpec.size}
              pageNumberValue={pageSpec.page}
            />
          </div>
        </div>

        <ShowOnScroll {...props} refNode={downloaderRef}>
          <AppBar
            className={classes.appBar}
            style={{ margin: 0 }}
            elevation={1}
          >
            {downloader()}
          </AppBar>
        </ShowOnScroll>

        <Box className={classes.tableBox}>
          {cartData?.organisms?.length > 0 && isLoading ? (
            "Loading ..."
          ) : (
            <GenomeTable
              {...props}
              setSelectedFiles={() => {}} // not used in cart
              disableRelevancy
              pageSpec={pageSpec}
              cartData={data}
            />
          )}
        </Box>
      </>

      {clearCartDialog && (
        <NoDraggableDialog
          title="All Files Will Be Removed From Cart"
          acceptBtnText="Remove All Files"
          acceptBtnFn={() => {
            handleClearCart();
            setClearCartDialog(false);
          }}
          cancelCrossFn={() => {
            setClearCartDialog(false);
          }}
          cancelBtnText="Cancel"
          cancelBtnFn={() => {
            setClearCartDialog(false);
          }}
          width={800}
          // height={400}
        >
          <div>
            Are you sure you want to remove <strong>all</strong> files from your
            cart?
          </div>

          <div style={{ paddingTop: 20 }}>This action is not reversible.</div>
        </NoDraggableDialog>
      )}
    </>
  );
};

export default CartPage;

CartPage.defaultProps = {
  honeycomb: null,
  ldClient: null,
};

CartPage.propTypes = {
  honeycomb: PropTypes.shape(),
  ldClient: PropTypes.shape(),
};
