/* istanbul ignore file */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Typography from "@mui/material/Typography";

import IconWithCounterBadge, {
  ICON_FILTER,
} from "../../../UI/IconWithBadge/IconWithCounterBadge";
import theme from "../../../../themes/theme.module.css";
import classes from "./FilterTab.module.css";
import FilterContainer from "../../../FilterContainer/FilterContainer";

import { useSearchParams } from "../../../../hooks/useSearchParams";
import Tooltip, { ICON_NONE } from "../../../UI/Tooltip/Tooltip";
import {
  setLocalStorageMiniFilter,
  getLocalStoreMiniFilter,
} from "../../../../utils/LocalStorageUtils";

const FilterTab = ({ onClick, filterGroups, setFilterGroups, ...props }) => {
  const miniFilter = getLocalStoreMiniFilter();
  const history = useHistory();
  const [open, _setOpen] = useState(!miniFilter);

  const setOpen = (val) => {
    _setOpen(val);
    setLocalStorageMiniFilter(val ? null : 1);
  };

  const { searchParams, FILE_NAME_REGX_KEY } = useSearchParams();

  const getFilterCounterMap = (urlParams) => {
    const countMap = {};

    filterGroups.forEach((d) => {
      const list = d.config;
      const { label } = d;
      countMap[label] = 0;
      // console.log("[FilterTab]", filterGroups, d, label);
      if (list) {
        let catcount = 0;
        for (let i = 0, len = list.length; i < len; i++) {
          const config = list[i];

          const urlFilter = urlParams.get(config.filterId);

          if (urlFilter) {
            const filterVals = urlFilter.split(",");
            catcount += filterVals.length;
          }
        }
        countMap[label] = catcount;
      }
    });
    if (urlParams.get(FILE_NAME_REGX_KEY)) {
      countMap["File Property"] += 1;
    }

    // console.log("[FilterTab]", countMap);
    return countMap;
  };

  const [filterCountMap, setFilterCountMap] = useState(
    getFilterCounterMap(searchParams)
  );
  // re-count filters when URL changes
  useEffect(() => {
    return history.listen((loc) => {
      const urlParams = new URLSearchParams(loc.search);
      setFilterCountMap(getFilterCounterMap(urlParams));
    });
  }, [history]);

  const filterCount = () =>
    Object.keys(filterCountMap).reduce(
      (total, key) => total + filterCountMap[key],
      0
    );

  const headerStyle = {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "10px 0px 2px",
    borderBottom: open ? `solid 2px #0F9AB7` : null,
    height: 48,
  };

  const labelFont = {
    color: "#5C5C5C",
    fontFamily: "Public Sans",
    fontSize: 14,
    fontWeight: 700,
    textTransform: "uppercase",
  };

  const totalFilterInUse = filterCount();
  const iconWithNumber = (
    <Tooltip
      iconType={ICON_NONE}
      title="Number of filters applied"
      bottomOffset={-6}
    >
      <Box>
        <IconWithCounterBadge
          iconType={ICON_FILTER}
          iconColor={totalFilterInUse === 0 ? theme.grey300 : theme.lake500}
          count={totalFilterInUse}
          cursor={open ? "default" : "pointer"}
          badgeBackgroundColor="#FFF"
          badgeColor="#000"
          badgeBorderColor={theme.lake500}
        />
      </Box>
    </Tooltip>
  );

  const labelText = (
    <Typography className={open ? "" : classes.vertialLable} style={labelFont}>
      FILTERS
    </Typography>
  );

  const OPEN_WIDTH = 220;
  const CLOSE_WIDTH = 50;
  const HEADER_WIDTH = OPEN_WIDTH - 2;

  if (open) {
    const filtersStyle = {};
    filtersStyle.height = "auto";

    return (
      <Box
        className="FilterTabBox"
        style={{ marginTop: 6, width: HEADER_WIDTH }}
      >
        <Box className="SideFilterHeaderBox" sx={headerStyle}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              {iconWithNumber}
            </Grid>
            <Grid item xs={4}>
              {labelText}
            </Grid>
            <Grid item xs={4}>
              <ChevronLeftIcon
                sx={{
                  cursor: "pointer",
                  color: theme.grey300,
                  fontSize: 48,
                  margin: "0px 0px 0px 20px",
                }}
                style={{ position: "relative", top: -14 }}
                onClick={open ? () => setOpen(!open) : null}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className="SideFilterBodyBox" style={{ height: "auto" }}>
          <FilterContainer
            {...props}
            filterGroups={filterGroups}
            filterCountMap={filterCountMap}
          />
        </Box>
        {/* Footer fixed to bottom; add a hidden row so the last data row is above the footer */}
        <Box
          style={{
            width: "auto",
            marginBottom: 100,
            badgeBackgroundColor: "#999",
          }}
        />
      </Box>
    );
  }

  const labelStyle = { margin: "90px 16px" };

  // if (iconTop < 10) {
  //   labelStyle.position = "fixed";
  //   labelStyle.top = iconTop < -70 ? 80 : 14;
  //   labelStyle.left = -3;
  // }

  return (
    <Box
      className="FilterTabBox"
      style={{
        padding: "20px 0px",
        width: CLOSE_WIDTH,
        cursor: "pointer",
        height: "100%",
      }}
      onClick={setOpen}
    >
      <Box>{iconWithNumber}</Box>
      <Box style={labelStyle}>{labelText}</Box>
    </Box>
  );
};

export default FilterTab;

FilterTab.defaultProps = {
  onClick: () => {},
  filterGroups: null,
  setFilterGroups: () => {},
};
FilterTab.propTypes = {
  onClick: PropTypes.func,
  setFilterGroups: PropTypes.func,
  filterGroups: PropTypes.arrayOf(PropTypes.shape()),
};
