import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import PagingUI, { PAGE_SIZE_ARRAY } from "./PagingUI";
import { useSearchParams } from "../../../hooks/useSearchParams";
import { toInt } from "../../../utils/StringUtils";
import numUtil from "../../../utils/NumericUtils";

export const newSearchParam = (searchParams, config) => {
  // console.log("[newSearchParam]", searchParams, config);
  const rtn = {
    changed: false,
  };

  /* istanbul ignore next */
  if (config.type === "page") {
    if (config.value === config.default) {
      if (searchParams.get("p")) {
        searchParams.delete("p");
        rtn.changed = true;
      }
    } else {
      searchParams.set("p", config.value);
      rtn.changed = true;
    }
  } else if (config.type === "size") {
    if (config.value === config.default) {
      if (searchParams.get("x")) {
        searchParams.delete("x");
        rtn.changed = true;
      }
    } else {
      searchParams.set("x", config.value);
      rtn.changed = true;
    }

    // new page max
    const page = searchParams.get("p");

    /* istanbul ignore next */
    rtn.pageMax =
      config.value >= config.total ? 1 : Math.ceil(config.total / config.value);
    if (page && parseInt(page, 10) > rtn.pageMax) {
      if (searchParams.get("p")) searchParams.delete("p");
      rtn.resetPage = true;
    }
  }
  rtn.value = `?${searchParams.toString()}`;

  return rtn;
};
const Pagination = (props) => {
  // console.log("[Pagination]", JSON.stringify(props));
  const { dataSize, defautPageSize, pageSizeArray } = props;
  const { sizeChangeHandler, pageChangeHandler } = props;

  const { searchParams } = useSearchParams();

  const [pageSize, setPageSize] = useState(defautPageSize);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);

  const history = useHistory();

  // console.log(
  //   `[paging] dataSize=${dataSize}, pageSize=${pageSize}, pageTotal=${pageTotal}`
  // );
  useEffect(() => {
    setPageTotal(numUtil.PageMax(dataSize, pageSize));
  }, [pageSize, dataSize]);

  useEffect(() => {
    const psize = toInt(searchParams.get("x"), defautPageSize); // default value can from config
    const page = toInt(searchParams.get("p"), 1); // default can from config

    if (psize !== pageSize) setPageSize(psize);
    if (page !== pageNumber) setPageNumber(page);
  }, [searchParams]);

  const pageSizeChanged = (newSize) => {
    if (newSize === pageSize) return;

    const newParam = newSearchParam(searchParams, {
      type: "size",
      value: newSize,
      default: defautPageSize,
      total: dataSize,
    });

    /* istanbul ignore next */
    if (newParam.changed) {
      if (typeof sizeChangeHandler === "function") {
        newParam.changed = sizeChangeHandler(newSize); // the newParam.changed can be aborted by client
      }

      if (newParam.changed) {
        setPageTotal(newParam.pageMax);
        setPageSize(newSize);

        if (newParam.resetPage) setPageNumber(1);

        history.push(newParam.value);
      }
    }
  };

  /* istanbul ignore next */
  const pageNumChanged = (newPage) => {
    if (newPage === pageNumber) return;

    const newParam = newSearchParam(searchParams, {
      type: "page",
      value: newPage,
      default: 1,
    });

    if (newParam.changed) {
      if (typeof pageChangeHandler === "function") {
        newParam.changed = pageChangeHandler(newPage); // the update can be aborted by client
      }

      if (newParam.changed) {
        setPageNumber(newPage);
        history.push(newParam.value);
      }
    }
  };

  // console.log("[pagination]", pageTotal, pageSize, pageNumber, dataSize);
  return (
    <PagingUI
      pageTotal={pageTotal}
      pageSizeValue={pageSize}
      pageNumberValue={pageNumber}
      pageSizeChanged={pageSizeChanged}
      pageNumChanged={pageNumChanged}
      pageSizeArray={pageSizeArray}
    />
  );
};

export default Pagination;

Pagination.defaultProps = {
  dataSize: 1,
  sizeChangeHandler: null,
  pageChangeHandler: null,
  defautPageSize: PAGE_SIZE_ARRAY[0],
  pageSizeArray: PAGE_SIZE_ARRAY,
};

Pagination.propTypes = {
  defautPageSize: PropTypes.number,
  sizeChangeHandler: PropTypes.func,
  pageChangeHandler: PropTypes.func,
  dataSize: PropTypes.number,
  pageSizeArray: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
};
