import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Button from "../../Button/Button";
import { Typography } from "../../../../node_modules/@material-ui/core/index";

const ControlButtons = ({
  selected,
  onManageAccess,
  handleRefineAndDownload,
  handleManageFiles,
  admin,
}) => {
  const btnStyle = {
    margin: 8,
    height: 40,
    width: 200,
    minWidth: 100,
  };
  const labelStyle = {
    fontFamily: "Public Sans, sans-serif",
    fontSize: 19,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 19,
    letterSpacing: 0.32,
    textAlign: "left",
    textTransform: "none",
  };

  return (
    <Box>
      <Button
        data-testid="datagrid-ctrl-btn-manage-access"
        size="medium"
        style={btnStyle}
        disabled={!selected?.length > 0}
        onClick={onManageAccess}
      >
        <Typography style={labelStyle} noWrap>
          Manage Access
        </Typography>
      </Button>
      {admin && (
        <Button
          size="medium"
          style={{ ...btnStyle, width: 260 }}
          disabled={!selected?.length > 0}
          onClick={handleManageFiles}
          variant={selected?.length > 0 ? "outlined" : "contained"}
        >
          <Typography style={labelStyle} noWrap>
            Manage File Properties
          </Typography>
        </Button>
      )}
      <Button
        size="medium"
        style={{ ...btnStyle, width: 260 }}
        disabled={!selected?.length > 0}
        onClick={handleRefineAndDownload}
        variant={selected?.length > 0 ? "outlined" : "contained"}
      >
        <Typography style={labelStyle} noWrap>
          Select & Download Files
        </Typography>
      </Button>
    </Box>
  );
};

export default ControlButtons;

ControlButtons.defaultProps = {
  selected: null,
  admin: false,
};

ControlButtons.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.number),
  onManageAccess: PropTypes.func.isRequired,
  handleRefineAndDownload: PropTypes.func.isRequired,
  handleManageFiles: PropTypes.func.isRequired,
  admin: PropTypes.bool,
};
