import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import NotesIcon from "@material-ui/icons/Notes";
import Box from "@mui/material/Box";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";
import MuiButton from "@mui/material/Button";
import Button from "../../Button/Button";
import "../../../App.css";

import cookieUtil from "../../../utils/CookieHandler";
import { JDP_NO_CL_API_URL } from "../../../utils/HttpClientProvider";
import ModalContentBase from "../../UI/InfoBoard/ModalContentBase";

import TransientTooltip from "../../UI/Tooltip/TransientTooltip/TransientTooltip";

import classes from "../../UI/InfoBoard/InfoBoard.module.css";
import { HREF_DOC_CURL_DOWNLOAD } from "../../../utils/URLConstants";
import Dialog from "../../UI/Dialogs/DraggableDialog/DraggableDialog";

const CurlDownloadDlg = ({ close, data, file, currentUser, honeycomb }) => {
  const [fullIds, setFullIDs] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const baseUrl = process.env.REACT_APP_FILES_API_URL || JDP_NO_CL_API_URL;
  const url = `${baseUrl}/filedownload/`;
  const cookie = currentUser.keycloak
    ? `${cookieUtil.KC_COOKIE_KEY}=${cookieUtil.getKCSessionCookie()}`
    : `${cookieUtil.SSO_COOKIE_KEY}=${cookieUtil.getSSOCookie()}`;
  const header = '"Content-Type: application/json"';

  const curlCmdHead = `curl --cookie ${cookie} --output ${file}`;
  const curlCmdTail = `-H ${header} ${url}`;
  const params = { ...data };

  /* curl takes JSON as a payload, and to make that JSON compatible
  with Windows, we have to escape the double quotes.  Calling JSON.stringify()
  twice does that for us reliably. The escaped JSON also works on Mac.
  Info here: https://stackoverflow.com/a/44035580/5351022 */
  const payloadStr = JSON.stringify(JSON.stringify(params));

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1500);
  };

  const urlcmd = (payloadstr, full = true) => {
    return `${curlCmdHead} -d ${full ? payloadstr : "{ ... }"} ${curlCmdTail}`;
  };

  const code = urlcmd(payloadStr);

  let text = code;
  const needTrucate =
    curlCmdHead.length + curlCmdTail.length + payloadStr.length > 1500;

  if (!fullIds && needTrucate) {
    text = urlcmd(payloadStr, !needTrucate);
  }

  const learnMore = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      component={RouterLink}
      target="_blank"
      to={HREF_DOC_CURL_DOWNLOAD}
      onClick={() => {
        honeycomb.sendUiInteractionSpan(
          "command-line-download-learn-more-clicked"
        );
      }}
    >
      <span className={classes.learnMoreText}>
        Learn more about command line download
      </span>
    </Link>
  );

  const copyToClipboardRef = useRef(null);
  const copyBtn = (
    <MuiButton
      // class from themes didn't work here
      sx={{
        textTransform: "none",
        fontSize: 14,
        fontFamily: "Public Sans, sans-serif",
        fontStyle: "normal",
        fontWeight: 600,
        // lineHeight: 14,
        letterSpacing: 0.16,
        textAlign: "left",
      }}
      type="button"
      variant="contained"
      size="small"
      color="secondary"
      style={{ marginRight: 10 }}
      onClick={(e) => {
        copyToClipboardRef.current.onClick(e);
      }}
    >
      <FileCopyIcon className={classes.copyIcon} />
      Copy to Clipboard
    </MuiButton>
  );
  const mainBody = (
    <Box>
      <Box>
        <Box className={classes.mainBorder}> {text} </Box>
        <Box className={classes.flexText}>
          <Box className={classes.learnMore}>{learnMore}</Box>
        </Box>
      </Box>

      <Box className={classes.buttonContainer}>
        <CopyToClipboard
          text={code}
          ref={copyToClipboardRef}
          onCopy={() => {
            handleTooltipOpen();
            honeycomb.sendUiInteractionSpan(
              "command-line-download-copy-to-clipboard-clicked"
            );
          }}
        >
          <TransientTooltip
            noticeLabel="Value copied to clipboard"
            child={copyBtn}
            open={tooltipOpen}
          />
        </CopyToClipboard>

        {!fullIds && needTrucate && (
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            style={{ marginLeft: 10 }}
            onClick={() => {
              honeycomb.sendUiInteractionSpan(
                "command-line-download-view-full-command-clicked"
              );
              setFullIDs(true);
            }}
          >
            <NotesIcon className={classes.notePadIcon} />
            View full command
          </Button>
        )}
        <Button
          variant="outlined"
          color="secondary"
          className=""
          size="small"
          onClick={close}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );

  const title = "Command line download";

  const fileCount = () => {
    let cnt = 0;
    if (data.ids) {
      Object.keys(data.ids).forEach((oid) => {
        cnt += data.ids[oid].length;
      });
    }

    return cnt;
  };
  const subTitle = data.ids
    ? `Organisms(${Object.keys(data.ids).length}), files(${fileCount()})`
    : data.restoreid
    ? `IMG bulk download request id : ${data.restoreid}`
    : "";

  return (
    <Dialog>
      <ModalContentBase
        titleText={title}
        subTitleText={subTitle}
        closeFn={close}
      >
        {mainBody}
      </ModalContentBase>
    </Dialog>
  );
};

export default CurlDownloadDlg;

CurlDownloadDlg.defaultProps = {
  data: { ids: [], organisms: 0, filecnt: 0 },
  close: null,
  file: "download.zip",
  currentUser: null,
};

CurlDownloadDlg.propTypes = {
  data: PropTypes.shape(),
  close: PropTypes.func,
  file: PropTypes.string,
  currentUser: PropTypes.shape(),
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }).isRequired,
};
