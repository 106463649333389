import React, { useState, useRef, useEffect } from "react";

import { Toolbar, useScrollTrigger } from "@material-ui/core";
import Box from "@mui/material/Box";

import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import cookieUtil from "../../utils/CookieHandler";
import FileStats from "./FileStats/FileStats";
import DropdownButton from "../Button/DropdownButton/DropdownButton";
import AddToCart from "./AddToCart";
import SkeletonDownloader from "./SkeletonDownloader";

import { useFileAndPageContext } from "../../context/FileAndPageContext";

import { keycloakff, dupVersion } from "../../utils/LDFFUtils";

import DUPDlg from "./dialogs/DUPDlg";
import ErrDlg from "./dialogs/ErrDlg";
import BrowserDownloadOKDlg from "./dialogs/BrowserDownloadOKDlg";
import CurlDownloadDlg from "./dialogs/CurlDownloadDlg";
import TapeFileRestoreDlg from "./dialogs/TapeFileRestoreDlg";
import ConfirmDownloadActionDlg, {
  FROM_CURL_DOWNLOAD,
  FROM_BROWSER_DOWNLOAD,
} from "./dialogs/ConfirmDownloadActionDlg";
import FileAvailabilityDlg from "./dialogs/FileAvailabilityDlg";
import GlobusDownloadDlg from "./dialogs/GlobusDownloadDlg";
import MoreThanOnePageDlg from "./dialogs/MoreThanOnePageDlg";

import ShoppingCart from "../UI/ShoppingCart/ShoppingCart";
import SelectAllButton from "./SelectAllButton/SelectAllButton";

import {
  RESTORE_WILL_OVER_USER_QUOTA,
  RESTORE_OVER_USER_QUOTA,
  RESTORE_NEAR_USER_QUOTA,
  RESTORE_DISK_SPACE,
} from "../Alert/PageAlert/PageAlert";

import {
  JDP_NO_CL_API_URL,
  whoami,
  restoreFiles,
  restoredFilesAll,
} from "../../utils/HttpClientProvider";
import { useApiUtil } from "../../hooks/useApiUtil";
import googlea from "../../utils/GoogleAnalytics";

import {
  BYTES_IN_GB,
  BYTES_IN_TB,
  getGlobusDownloadSizeInByte,
  getBrowserDownloadWarningSizeInByte,
  createDownloadFilename,
  selectionSummary,
} from "../../utils/FileUtils";

import { useSearchParams } from "../../hooks/useSearchParams";

import BusyBackdrop from "../UI/BusyBackdrop/BusyBackdrop";
import numUtil from "../../utils/NumericUtils";

import "../../App.css";

const VIA_BROWSER = "VIA_BROWSER";
const VIA_CURL = "VIA_CURL";
const VIA_GLOBUS = "VIA_GLOBUS";
const DIALOG_ERR = "DIALOG_ERR";
const DIALOG_DUP = "DIALOG_DUP";
const DIALOG_DOWNLOAD_OK = "DIALOG_DOWNLOAD_OK";
const DIALOG_TAPEFILE_RESTORE = "DIALOG_TAPEFILE_RESTORE";
const DIALOG_TAPEFILE_RESTORE_FETCHING_DISK_INFO =
  "DIALOG_TAPEFILE_RESTORE_FETCHING_DISK_INFO";
const DIALOG_TAPEFILE_RESTORE_HANDLED = "DIALOG_TAPEFILE_RESTORE_HANDLED";
const DIALOG_FORCE_USE_BROWSER = "DIALOG_FORCE_USE_BROWSER";
const DIALOG_FORCE_USE_CURL = "DIALOG_FORCE_USE_CURL";
const DIALOG_GLOBUS_DOWNLOAD = "DIALOG_GLOBUS_DOWNLOAD";
const DIALOG_FILE_AVAILABILITY = "DIALOG_FILE_AVAILABILITY";
const DIALOG_MORE_PAGE = "DIALOG_MORE_PAGE";

const DATA_TYPE_PAGE = "DATA_TYPE_PAGE"; // a page data
const DATA_TYPE_FULL = "DATA_TYPE_FULL"; // for restore request - entire requested data

const Downloader = (props) => {
  const {
    isLoading,
    files,
    honeycomb,
    currentUser,
    setAppMessageID,
    ldClient,
    headerCartThreshold,
    imgBulk,
    fullData,
    setSelectedFiles, // SearchPageContainer.setSelectedFiles
    dataSize,
    pageSize,
  } = props;

  const {
    restoreid,
    its_ap_id,
    its_sp_id,
    organism,
    cart: cartpage,
  } = useSearchParams();

  const { preProcessData } = useApiUtil();

  const { clearCartData, handleLogin } = useFileAndPageContext();

  const [via, setVia] = useState(null);

  const [dialogType, _setDialogType] = useState(null);
  const [downloaderEmphasize, setDownloaderEmphasize] = useState(false);

  const [fetchQuotaDisk, setFetchQuotaDisk] = useState(false);
  const [diskStatus, setDiskStatus] = useState(null);

  const formSubmitBtnRef = useRef();
  const imgBulkDownloadFormSubmitBtnRef = useRef();

  const [fileSummary, setFileSummary] = useState(null);

  const [fileMeta, setFileMeta] = useState({ disabled: true });

  const location = useLocation();

  const toks = location?.pathname?.split("/refine-download/");
  const dataCategory = toks?.length === 2 ? toks[1].toLowerCase() : null;

  const trigger = useScrollTrigger({
    threshold: headerCartThreshold,
    disableHysteresis: true,
  });

  const downloadData = (fData = null) => {
    const fdata = fData || files;
    if (!imgBulk && fdata?.files?.length > 0) {
      const ids = {};
      fdata.files.forEach((f) => {
        const oid = f.organism_id;
        if (!ids[oid]) {
          ids[oid] = [];
        }
        ids[oid].push(f._id);
      });

      if (Object.keys(ids).length > 0) {
        const disabled = imgBulk ? false : !(files?.files?.length > 0);
        setFileMeta({
          ids: disabled || imgBulk ? null : ids,
          disabled,
          dataType: fData ? DATA_TYPE_FULL : DATA_TYPE_PAGE,
        });
      }
    } else {
      setFileMeta({ disabled: true });
    }
  };

  const downloadPayload = () => {
    const payload = { ids: fileMeta.ids };
    if (organism && dataCategory === "mycocosm") {
      payload.organism = organism;
    }

    if (its_ap_id && dataCategory === "img") {
      payload.its_ap_id = its_ap_id;
    }

    if (its_sp_id && dataCategory === "img") {
      payload.its_sp_id = its_sp_id;
    }

    if (files.include_private_data) {
      payload.include_private_data = 1;
    }

    return payload;
  };

  useEffect(() => {
    if (!isLoading) {
      downloadData();

      if (diskStatus) {
        setDiskStatus(null);
      }

      const selectedFiles = files?.files?.length > 0;
      setDownloaderEmphasize(selectedFiles); // highlight/un-highlight the selecion info
      if (!selectedFiles) {
        setFileSummary(null);
      } else {
        setFileSummary(selectionSummary(files.files));

        const timerId = setTimeout(() => {
          setDownloaderEmphasize(false); // delayed remove selecion info highlight
        }, 3000);
        return () => {
          if (timerId) clearTimeout(timerId);
        };
      }
    }
  }, [files, isLoading]);

  const setDialogType = (val) => {
    if (!val && fileMeta.dataType === DATA_TYPE_FULL) {
      // a download of full data set just completed, reset data to page data
      downloadData();
    }
    _setDialogType(val);
  };
  const canDownload = () => {
    const dupVer = dupVersion(ldClient); // "1.0";
    const agreed = cookieUtil.isDUPAgreed(currentUser.key, dupVer);
    if (!agreed) {
      setDialogType({ type: DIALOG_DUP });
      return false;
    }
    return true;
  };

  const handleBrowserDownload = () => {
    const trace = honeycomb.buildTrace(honeycomb.getTraceID());
    const tstart = Date.now();
    if (imgBulk) {
      setDialogType({ type: DIALOG_DOWNLOAD_OK });
      googlea.add("browser-download-img-bulk");
      const hcPayload = {
        type: `IMG bulk ${restoreid}`,
        via,
      };

      honeycomb.sendDownloadSpan("browser-download-start", hcPayload, trace);
      imgBulkDownloadFormSubmitBtnRef.current.click();
    } else if (canDownload()) {
      // clear the force-browser download param in localStorage
      setDialogType({ type: DIALOG_DOWNLOAD_OK });

      // add google analytics and HC trace
      googlea.add("browser-download");
      const hcPayload = {
        dialog: dialogType?.type,
        duration: Date.now() - tstart,
        status: 200,
        file_count: fileSummary.ondiskCount,
        file_size: fileSummary.ondiskSizeInBytes,
        genome_count: fileSummary.organismCount,
        via,
      };

      honeycomb.sendDownloadSpan("browser-download-start", hcPayload, trace);
      cookieUtil.setHCTraceCookie(honeycomb.getTraceHeader(trace));

      // trigger download form submission
      formSubmitBtnRef.current.click();
      // clear cart
      clearCartData();
    }
  };

  const handleRestoreFiles = () => {
    honeycomb.sendUiInteractionSpan("clicked-request-restore-files");
    const ids = [];
    const organisms = new Set();
    files.files.forEach((f) => {
      ids.push(f._id);
      organisms.add(f.organism_id);
    });

    const data = {
      ids,
      organisms: Array.from(organisms),
      href: location.pathname,
      api_version: "2",
      send_mail: true,
      send_confirmation: true,
    };

    if (organism && dataCategory === "mycocosm") {
      data.organism = organism;
    }

    if (its_ap_id && dataCategory === "img") {
      data.its_ap_id = its_ap_id;
    }

    if (its_sp_id && dataCategory === "img") {
      data.its_sp_id = its_sp_id;
    }

    if (files.include_private_data) {
      data.include_private_data = 1;
      data.href = `${data.href}?include_private_data=1`;
    }

    const trace = honeycomb.buildTrace(honeycomb.getTraceID());

    googlea.add("tape-download-request");
    const tStart = Date.now();
    const promise = restoreFiles(honeycomb.getTraceHeader(trace), data);
    promise.then((resp) => {
      if (resp.error) {
        const hcPayload = {
          requested_file_count: fileSummary?.purgedIds.length,
          requested_files: fileSummary?.purgedIds,
          duration: Date.now() - tStart,
          error: resp.error,
        };
        if (resp.response) {
          hcPayload.status = resp.response.status;
        }
        honeycomb.sendDownloadSpan("request-files-failure", hcPayload, trace);
        setDialogType({ type: DIALOG_ERR });
      } else {
        const hcPayload = {
          requested_file_count: fileSummary?.purgedIds.length,
          requested_files: fileSummary?.purgedIds,
          requested_file_size: fileSummary?.purgedSizeInBytes,
          status: 200,
          duration: Date.now() - tStart,
        };
        honeycomb.sendDownloadSpan("request-files-success", hcPayload, trace);

        // clear cart
        clearCartData();
        setDialogType({
          type: DIALOG_TAPEFILE_RESTORE_HANDLED,
          value: resp.data.request_id,
          data,
        });
      }
    });
  };

  useEffect(() => {
    if (fetchQuotaDisk) {
      const promise = whoami();
      promise
        .then((resp) => {
          if (resp.status === 200) {
            let aid = null;
            let value = null;
            const thisSize = fileSummary?.purgedSizeInBytes;
            const disk_space = resp.data.available_disk_space;
            const remaining_quota = resp.data.daily_disk_remaining;
            const user_quota = Math.min(disk_space, remaining_quota); //

            const hc_payload = {
              "request-size": thisSize,
              "available-disk-space": disk_space,
              "remaining-user-quota": remaining_quota,
            };

            // console.log("[Downloader]", thisSize, user_quota);
            if (disk_space === 0) {
              // no free disk space
              hc_payload["warning-type"] = "disk full";
              honeycomb.sendUiInteractionSpan("disk-warning", hc_payload);
              aid = RESTORE_DISK_SPACE;
            } else if (remaining_quota === 0) {
              // 2.1 : user used up quota
              hc_payload["warning-type"] = "user quota used up";
              honeycomb.sendUiInteractionSpan("disk-warning", hc_payload);
              aid = RESTORE_OVER_USER_QUOTA;
            } else if (
              user_quota >= thisSize &&
              user_quota <= 0.5 * BYTES_IN_TB
            ) {
              // 3.1 : If a user is at or over 9.5 TB but their request wouldn’t take me over 10TB
              hc_payload["warning-type"] = "near user quota";
              honeycomb.sendUiInteractionSpan("disk-warning", hc_payload);

              aid = RESTORE_NEAR_USER_QUOTA;
            } else if (thisSize > user_quota) {
              // 1.1 : user will over quota
              hc_payload["warning-type"] = "will over user quota";
              honeycomb.sendUiInteractionSpan("disk-warning", hc_payload);
              aid = RESTORE_WILL_OVER_USER_QUOTA;
              value = (thisSize - user_quota) / BYTES_IN_GB; // size over quota in GB
              value = value > 1 ? value.toFixed(2) : value;
            }

            // JDP-1075: To show each of the dialogs:
            /*
    assign each of the following consts to aid and try out tape file request from app:
      RESTORE_WILL_OVER_USER_QUOTA,
      RESTORE_OVER_USER_QUOTA,
      RESTORE_NEAR_USER_QUOTA,
      RESTORE_DISK_SPACE,
  */
            // aid = RESTORE_WILL_OVER_USER_QUOTA;
            // value = 10;

            const sdata = aid ? { id: aid, value } : null;
            setDiskStatus(sdata);

            if (aid === null) {
              handleRestoreFiles();
            }
          } else {
            throw new Error(resp.error);
          }
        })
        .finally(() => {
          setFetchQuotaDisk(false);
        });
    }
  }, [fetchQuotaDisk]);

  const doDownload = (dtype) => {
    const viaType = [VIA_BROWSER, VIA_CURL].includes(dtype) ? dtype : via;

    if (canDownload()) {
      switch (viaType) {
        case VIA_BROWSER:
          handleBrowserDownload();
          break;
        case VIA_CURL:
          setDialogType({ type: DIALOG_DOWNLOAD_OK });
          break;
        default:
          setDialogType(null);
      }
    }
  };

  const handlePreDownload = (dtype, checkpage = true) => {
    if (
      checkpage &&
      restoreid &&
      dataSize &&
      pageSize &&
      numUtil.PageMax(dataSize, pageSize) > 1
    ) {
      setDialogType({
        type: DIALOG_MORE_PAGE,
        downloadType: dtype,
        data: files,
      });
      return;
    }

    if (dtype !== via) {
      setVia(dtype);
    }

    if ([VIA_BROWSER, VIA_CURL].includes(dtype)) {
      if (imgBulk) {
        doDownload(dtype);
      } else if (fileSummary?.purgedSizeInBytes > 0) {
        setDialogType({ type: DIALOG_TAPEFILE_RESTORE });
      } else {
        const cSizeInByte = fileSummary?.ondiskSizeInBytes;
        const warningSize = getBrowserDownloadWarningSizeInByte(ldClient);
        const globusSize = getGlobusDownloadSizeInByte(ldClient);
        if (dtype === VIA_CURL && cSizeInByte > globusSize) {
          setDialogType({ type: DIALOG_FORCE_USE_CURL });
        } else if (dtype === VIA_BROWSER && cSizeInByte > warningSize) {
          setDialogType({ type: DIALOG_FORCE_USE_BROWSER });
        } else {
          doDownload(dtype);
        }
      }
    } else if (dtype === VIA_GLOBUS) {
      setDialogType({ type: DIALOG_GLOBUS_DOWNLOAD });
    }
  };

  const curlDownloadToHC = () => {
    const hcPayload = imgBulk
      ? {
          imgBulk: restoreid,
          via,
        }
      : {
          dialog: dialogType?.type,
          file_count: files.files.length,
          file_size: fileSummary?.purgedSizeInBytes,
          genome_count: fileSummary?.organismCount,
          via,
        };

    honeycomb.sendDownloadSpan("built-api-command", hcPayload);
  };

  const handleDialogCancel = () => {
    const hcPayload = { dialog: dialogType?.type, via };
    honeycomb.sendUiInteractionSpan(
      `closed-dialog: ${dialogType?.type}`,
      hcPayload
    );

    setDialogType(null);
    setDiskStatus(null);
  };

  // use static file name in test to pass snapshot!
  const dfilename =
    process.env.NODE_ENV === "test" ? "download.zip" : createDownloadFilename();

  // JDP-1512 : do not disable browser download at all, also fallback to legacy
  // const disableMax = getGlobusDownloadSizeInByte(ldClient);
  const allowBrowserDownload = true;

  const modal = () => {
    let acceptFn = doDownload;
    switch (dialogType?.type) {
      case DIALOG_TAPEFILE_RESTORE:
        acceptFn =
          diskStatus?.id === RESTORE_NEAR_USER_QUOTA
            ? () => {
                handleRestoreFiles();
              }
            : () => {
                // click on "Request all files for download" -> check disk status
                setDialogType({
                  type: DIALOG_TAPEFILE_RESTORE_FETCHING_DISK_INFO,
                });
                setFetchQuotaDisk(true);
              };
        break;
      case DIALOG_TAPEFILE_RESTORE_HANDLED:
        acceptFn = null;
        break;
      case DIALOG_DUP:
        acceptFn = () => {
          setDialogType(null);
          doDownload();
        };
        break;
      case DIALOG_DOWNLOAD_OK:
        acceptFn = null;
        break;
      default:
        break;
    }

    if (dialogType?.type === DIALOG_DUP) {
      return (
        <DUPDlg
          currentUser={currentUser}
          handleCancel={handleDialogCancel}
          ldClient={ldClient}
          honeycomb={honeycomb}
          handleAccept={() => {
            setDialogType(null);
            doDownload();
          }}
        />
      );
    }
    if (dialogType?.type === DIALOG_ERR) {
      return <ErrDlg handleClose={() => {}} />;
    }
    if (dialogType?.type === DIALOG_DOWNLOAD_OK) {
      if (via === VIA_BROWSER) {
        return <BrowserDownloadOKDlg handleClose={handleDialogCancel} />;
      }
      if (via === VIA_CURL) {
        curlDownloadToHC();

        return (
          <CurlDownloadDlg
            close={handleDialogCancel}
            data={imgBulk ? { restoreid } : downloadPayload()}
            file={createDownloadFilename()}
            currentUser={currentUser}
            ldClient={ldClient}
            honeycomb={honeycomb}
          />
        );
      }
    }
    if (
      [
        DIALOG_TAPEFILE_RESTORE,
        DIALOG_TAPEFILE_RESTORE_FETCHING_DISK_INFO,
        DIALOG_TAPEFILE_RESTORE_HANDLED,
      ].includes(dialogType?.type)
    ) {
      return (
        <TapeFileRestoreDlg
          id="tape-file-restore"
          fetching={
            dialogType.type === DIALOG_TAPEFILE_RESTORE_FETCHING_DISK_INFO
          }
          closeBtnFn={handleDialogCancel}
          cancelBtnFn={handleDialogCancel}
          confirmBtnFn={acceptFn}
          diskStatus={diskStatus}
          handled={dialogType.type === DIALOG_TAPEFILE_RESTORE_HANDLED}
          requestId={dialogType.value}
          data={dialogType.data}
          ldClient={ldClient}
          currentUser={currentUser}
        />
      );
    }
    if (
      [DIALOG_FORCE_USE_BROWSER, DIALOG_FORCE_USE_CURL].includes(
        dialogType?.type
      )
    ) {
      return (
        <ConfirmDownloadActionDlg
          ftype={
            dialogType.type === DIALOG_FORCE_USE_CURL
              ? FROM_CURL_DOWNLOAD
              : FROM_BROWSER_DOWNLOAD
          }
          handleCancel={handleDialogCancel}
          handleAccept={acceptFn}
          fileSummary={fileSummary}
          ldClient={ldClient}
        />
      );
    }
    if (dialogType?.type === DIALOG_FILE_AVAILABILITY) {
      // when is this triggered??
      return <FileAvailabilityDlg handleCancel={handleDialogCancel} />;
    }
    if (dialogType?.type === DIALOG_GLOBUS_DOWNLOAD) {
      return (
        <GlobusDownloadDlg
          handleClose={handleDialogCancel}
          currentUser={currentUser}
          fileSummary={fileSummary}
          ldClient={ldClient}
          honeycomb={honeycomb}
          data={downloadPayload()}
        />
      );
    }

    if (dialogType?.type === DIALOG_MORE_PAGE) {
      return (
        <MoreThanOnePageDlg
          data={dialogType.data}
          handleDownloadAll={() => {
            // use the util to fetch the entire restore request dataset
            const promise = restoredFilesAll(restoreid, dataSize);
            promise.then((resp) => {
              const clearnData = preProcessData(resp.data);
              const alist = [];

              clearnData.organisms.forEach((org) => {
                if (org.files?.length > 0) {
                  org.files.forEach((file) => {
                    alist.push(file);
                  });
                }
              });

              const fullFiles = {
                files: alist,
                include_private_data: files.include_private_data,
              };
              downloadData(fullFiles);
              handlePreDownload(dialogType.downloadType, false);
            });
          }}
          handleDownloadPage={() => {
            setDialogType(null);
            handlePreDownload(dialogType.downloadType, false);
          }}
          handleCancel={() => {
            setDialogType(null);
          }}
        />
      );
    }

    return null;
  };

  const downloadItems = [
    {
      label: "Browser download",
      key: "browser-download-menu-item",
      id: "browser-download-btn-id",
      click: () => handlePreDownload(VIA_BROWSER),
      disabled: !allowBrowserDownload,
    },
    {
      label: "Command line download",
      key: "command-line-download-menu-item",
      id: "command-line-download-menu-item",
      click: () => handlePreDownload(VIA_CURL),
    },
  ];

  if (!imgBulk) {
    downloadItems.push({
      label: "Globus download",
      key: "globus-download-menu-item",
      id: "globus-download-btn-id",
      click: () => handlePreDownload(VIA_GLOBUS),
    });
  }

  // LD feature flags
  const keycloak_ff = keycloakff(ldClient);
  const needSSO =
    currentUser.name === "Anonymous" || (!currentUser.keycloak && keycloak_ff);

  const handleDownloadDropdownClick = () => {
    honeycomb.sendUiInteractionSpan("opened-download-dropdown");
    if (needSSO && handleLogin) {
      handleLogin();
    }
  };

  const downloadURL = `${JDP_NO_CL_API_URL}/filedownload/`;

  const { cartData } = useFileAndPageContext();
  const shoppingCart = restoreid ? null : (
    <Box
      style={{
        visibility: trigger ? "visible" : "hidden",
        width: 48,
        height: 48,
        borderRadius: 48,
        paddingTop: 10,
      }}
    >
      <ShoppingCart
        ldClient={ldClient}
        honeycomb={honeycomb}
        count={cartData?.organisms?.length}
        size="medium"
      />
    </Box>
  );

  return (
    <>
      <Toolbar
        className="DownloaderPanel"
        style={{
          backgroundColor: downloaderEmphasize ? "#F5FFB5" : "white",
          paddingLeft: 28,
          paddingRight: 12,
          borderRadius: 4,
        }}
      >
        {isLoading ? (
          <SkeletonDownloader />
        ) : (
          <>
            <FileStats
              fileSummary={fileSummary}
              setAppMessageID={setAppMessageID}
              ldClient={ldClient}
              cart={Boolean(cartpage)}
              imgBulk={imgBulk}
            />

            {fullData && (
              <SelectAllButton setSelectedFiles={setSelectedFiles} />
            )}

            {!restoreid && !cartpage ? (
              <>
                <AddToCart
                  disabled={fileMeta.disabled}
                  {...props}
                  files={files?.files}
                />
                {shoppingCart}
              </>
            ) : (
              <>
                <DropdownButton
                  dropdown={!needSSO}
                  onClick={handleDownloadDropdownClick}
                  onMenuClose={() => {
                    honeycomb.sendUiInteractionSpan("closed-download-dropdown");
                  }}
                  allowBrowserDownload={allowBrowserDownload}
                  disabled={!imgBulk && fileMeta.disabled}
                  label="Download"
                  items={downloadItems}
                  {...props}
                  imgBulk={imgBulk}
                />
                {shoppingCart}
              </>
            )}
          </>
        )}
      </Toolbar>

      {/* the hidden download form */}
      {imgBulk ? (
        <form action={downloadURL} method="POST" style={{ display: "none" }}>
          <input
            data-testid="form-input-ids"
            type="hidden"
            name="restoreid"
            value={restoreid}
          />
          <input type="hidden" name="zipname" value={dfilename} />
          <input
            ref={imgBulkDownloadFormSubmitBtnRef}
            type="submit"
            name="submit"
            value="submit"
          />
        </form>
      ) : (
        fileMeta.ids && (
          <form action={downloadURL} method="POST" style={{ display: "none" }}>
            <input
              data-testid="form-input-ids"
              type="hidden"
              name="ids"
              value={JSON.stringify(fileMeta.ids)}
            />
            {organism && dataCategory === "mycocosm" ? (
              <input type="hidden" name="organism" value={organism} />
            ) : null}
            {files?.include_private_data ? (
              <input type="hidden" name="include_private_data" value={1} />
            ) : null}
            <input type="hidden" name="href" value={location.pathname} />
            <input type="hidden" name="zipname" value={dfilename} />
            <input type="hidden" name="api_version" value="2" />
            <input
              ref={formSubmitBtnRef}
              type="submit"
              name="submit"
              value="submit"
            />
          </form>
        )
      )}

      {modal()}

      <BusyBackdrop open={fetchQuotaDisk} />
    </>
  );
};

export default Downloader;

Downloader.defaultProps = {
  files: null,
  currentUser: {},
  ldClient: null,
  honeycomb: {
    sendUiInteractionSpan: () => {},
    sendDownloadSpan: () => {},
    buildTrace: () => {},
    getTraceID: () => {},
    getTraceHeader: () => {},
  },
  headerCartThreshold: 0,
  imgBulk: null,
  fullData: null,
  setSelectedFiles: null,
  dataSize: null,
  pageSize: null,
};

Downloader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  files: PropTypes.shape({
    include_private_data: PropTypes.bool,
    files: PropTypes.arrayOf(PropTypes.shape()),
  }),
  ldClient: PropTypes.shape({
    variation: PropTypes.func,
  }),
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    keycloak: PropTypes.bool,
  }),

  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
    sendDownloadSpan: PropTypes.func,
    buildTrace: PropTypes.func,
    getTraceID: PropTypes.func,
    getTraceHeader: PropTypes.func,
  }),
  setAppMessageID: PropTypes.func.isRequired,
  headerCartThreshold: PropTypes.number,
  imgBulk: PropTypes.shape(),
  fullData: PropTypes.shape(),
  setSelectedFiles: PropTypes.func,
  dataSize: PropTypes.number,
  pageSize: PropTypes.number,
};
