/* istanbul ignore file */
import axios from "axios";
// for dev testing
import { testValue } from "./TestUtils";

export const JDP_API_URL = "https://files.jgi.doe.gov"; // the default backend API URL
export const JDP_NO_CL_API_URL = "https://files-download.jgi.doe.gov"; // the backend API URL bypasss cloudflare
export const JDP_API_WHOAMI = "whoami/";
export const JDP_API_FILERESTORE = "filerestore/";
export const JDP_API_FILEDOWNLOAD = "filedownload/";
export const JDP_API_GLOBUS_UNAME = "globus/username/";
export const JDP_API_GLOBUS_UID = "globus/user_id/";
export const JDP_API_PROJECTS = "projects/";
export const JDP_API_PROJ_COLLAB = "projects/collaborators/";
export const JDP_API_ORCID = "orcid/";
export const JDP_API_GRANT_ASSCESS = "projects/access/";
export const JDP_API_CART_DATA = "search/by_file_ids/";
export const JDP_API_ADV_SEARCH_KEYS = "search/fields/";

export const JDP_API_PRIVATE_DATA_PARAM = "include_private_data=1";

// axios
const httpclient = axios.create({
  baseURL: process.env.REACT_APP_FILES_API_URL || JDP_API_URL,
  withCredentials: true,
});

// axios http request error parser
export const errorParser = (error) => {
  // https://axios-http.com/docs/handling_errors
  const rtn = { config: error.config };
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    const { response } = error;
    rtn.data = response.data;
    rtn.status = response.status;
    rtn.header = response.headers;
    rtn.error = `The request was made and the server responded : ${response.status}; ${response.statusText}`;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    rtn.error = "The request was made but no response was received";
    rtn.request = error.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    rtn.error =
      "Something happened in setting up the request that triggered an Error";
    rtn.message = error.message;
  }
  return rtn;
};

/*
  the exposed http helpers return promises, and need to be used with the following pattern
    const p = whoami();
    p.then((DATA)=>{
      // consume DATA here
    })

    OR with useQuery() 
*/

export const whoami = async () => {
  let rtn = {};
  await httpclient
    .get(JDP_API_WHOAMI)
    .then((res) => {
      if (res.status === 200) {
        rtn = res;
        /*
        when with mimic_contact_id cookie:
          if https://contacts-stage2.jgi.doe.gov/api/contacts/10388.json
          1: returned non-null response but returned 404
            mimic: {"error": "contact ID not found"}
          2: returned null response - err is Exception
            mimic: {"error": str(err)}
          3: returned OK but have parsing error 
            mimic: {"error": str(json_failed)}
        */
      } else {
        rtn.status = res.status;
        rtn.error = `unexpected response from BE`;
        rtn.response = res;
      }
    })
    .catch((err) => {
      rtn = errorParser(err);
    });

  // console.log("[whoami returns]", rtn);
  return rtn;
};

export const getStatus = async () => {
  const resp = await httpclient
    .get("/status/")
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
      return Promise.reject(res);
    })
    .catch((err) => {
      return err;
    });
  if (resp.status !== 200) {
    throw new Error(resp);
  }

  return resp.data;
};

export const getAutocompleteSuggestions = async (
  suggestionUrl,
  query,
  honeycomb
) => {
  const DEFAULT_SUGGESTION_URL = "/search/?a=1&x=5";
  const url = suggestionUrl || DEFAULT_SUGGESTION_URL;
  const trace = honeycomb.buildTrace(honeycomb.getTraceID());
  const tstart = Date.now();

  const resp = await httpclient
    .get(url, {
      headers: honeycomb.getTraceHeader(trace),
      params: { q: query },
    })
    .then((res) => {
      if (res && res.data) {
        const hcPayload = {
          status: res.status,
          duration: tstart ? Date.now() - tstart : 0,
          autocomplete: true,
          query,
        };
        honeycomb.sendAxiosGetSpan(url, hcPayload, trace);
      }

      if (res.status === 200) {
        return res;
      }
      return Promise.reject(res);
    })
    .catch((err) => {
      return err;
    });
  if (resp.status !== 200) {
    throw new Error(resp);
  }
  return resp.data;
};

const hcHeader = (honeycomb) => {
  return honeycomb
    ? honeycomb.getTraceHeader(honeycomb.buildTrace(honeycomb.getTraceID()))
    : {};
};

// helper: translate project from BE to FE table data
const toTableData = (projects, contactId) => {
  const data = [];
  const fdids = [];
  let pi_project_count = 0;

  const getVisibility = (d) => {
    const counts = {
      ap_data_available: 0, // AP DataAvailable count
      ap: d.non_deleted_analysis_projects
        ? d.non_deleted_analysis_projects.length
        : 0, // total ap count
      sp_data_available: 0, // SP DataAvailable count
      sp: d.non_deleted_sequencing_projects
        ? d.non_deleted_sequencing_projects.length
        : 0, // total sp count
      nodata: true, // No AP or SP data
    };

    if (d.non_deleted_analysis_projects?.length > 0) {
      counts.nodata = false;
      d.non_deleted_analysis_projects.forEach((p) => {
        if (p.ap_visibility === "DataAvailable") {
          counts.ap_data_available += 1;
        }
      });
    }

    if (d.non_deleted_sequencing_projects?.length > 0) {
      counts.nodata = false;
      d.non_deleted_sequencing_projects.forEach((p) => {
        if (p.sp_visibility === "DataAvailable") {
          counts.sp_data_available += 1;
        }
      });
    }

    return counts;
  };

  const getPublicDate = (d) => {
    let publicDate = "TBD";

    const apsp = [];
    if (d.non_deleted_analysis_projects?.length > 0) {
      apsp.push(d.non_deleted_analysis_projects);
    }
    if (d.non_deleted_sequencing_projects?.length > 0) {
      apsp.push(d.non_deleted_sequencing_projects);
    }

    if (apsp.length > 0) {
      const dlist = [];
      const dstrlist = [];
      apsp.forEach((ar) => {
        ar.forEach((p) => {
          const dnum = Date.parse(p.availability_date);
          if (!Number.isNaN(dnum) && !dlist.includes(dnum)) {
            dlist.push(dnum);
            dstrlist.push(p.availability_date);
          }
        });
      });
      if (dlist.length > 0) {
        const dsorted = dlist.toSorted((a, b) => a - b); // sort numerically, in ascending order; keep the orignal order in dlist;
        publicDate = dstrlist[dlist.indexOf(dsorted[0])]; // return the string value of the datetime with smallest int value
        // date string in format of "2025-03-12T12:00:00.000-07:00", to only keep YYYY-MM-DD
        [publicDate] = publicDate.split("T");
      }
    }

    return publicDate;
  };

  projects.forEach((d) => {
    const fdid = d.final_deliv_project_id;
    const visibilityCount = getVisibility(d);
    const visibility = visibilityCount.nodata
      ? "n/a"
      : visibilityCount.ap_data_available > 0 ||
        visibilityCount.sp_data_available > 0
      ? "Public"
      : "Private";

    if (!fdids.includes(fdid)) {
      if (d.pi_contact_id === contactId) {
        pi_project_count += 1;
      }
      const row = {
        id: d.final_deliv_project_id,
        fdName: d.final_deliv_project_name,
        prodName: d.product_name,
        projName: d.final_deliv_project_name,
        pmName: d.pm_name,
        proposalID: d.proposal_id,
        PI: {
          name: d.pi_name,
          email: d.pi_email,
        },
        prodType: "TBD",
        projStatus: d.status_name,
        statusDate: d.status_date,
        dataUsage: "TBD",
        pi_contact_id: d.pi_contact_id,
        visibility,
        visibilityCount,
        available_date:
          visibility === "Public" || d.status_name === "Abandoned"
            ? "NA"
            : getPublicDate(d),
      };

      if (d.non_deleted_analysis_projects?.length > 0) {
        row.prodType =
          d.non_deleted_analysis_projects[0].analysis_product_type_name;
      } else {
        row.prodType = "UNKNOWN";
      }
      data.push(row);
      fdids.push(fdid);
    }
    // else {
    //   // in case there is duplicated FDID, shouldn't happen
    //   console.log("[toTableData] dup FDID", fdid);
    // }
  });

  return { pi_project_count, tabData: data };
};

export const collaborators = async (pids, honeycomb) => {
  const api = `${JDP_API_PROJ_COLLAB}?project_ids=${pids.join(",")}`;
  let rtn = {};
  const headers = hcHeader(honeycomb);
  await httpclient
    .get(api, { headers })
    .then((resp) => {
      if (resp?.data) {
        rtn.data = resp.data.projects;
      } else {
        rtn.error = "get malformed data";
      }
    })
    .catch((err) => {
      rtn = errorParser(err);
    });

  return rtn;
};

export const myProjects = async (contactId, honeycomb) => {
  const api = JDP_API_PROJECTS;
  let rtn = {};
  const headers = hcHeader(honeycomb);
  await httpclient
    .get(api, { headers })
    .then((resp) => {
      if (resp.status === 200) {
        const udata = resp.data;
        if (udata?.projects) {
          const { pi_project_count, tabData } = toTableData(
            udata.projects,
            contactId
          );
          rtn.projects = tabData;
          rtn.pi_project_count = pi_project_count;
          rtn.non_pi_project_count = tabData.length - pi_project_count;
        } else {
          rtn.data = udata;
          rtn.error = "no projects key in returned data";
        }
      } else {
        rtn.status = resp.status;
        rtn.error = `api server returned ${resp.status} : ${resp.text}`;
      }
    })
    .catch((err) => {
      rtn = errorParser(err);
    });

  return rtn; // resp.data?.globus_user;
};

export const grantAccess = async (data, honeycomb) => {
  const api = JDP_API_GRANT_ASSCESS;
  let rtn = {};
  const config = {
    headers: {
      ...hcHeader(honeycomb),
      "Content-Type": "application/json",
    },
  };
  // console.log(`[httpGrantAccess data]`, data);
  await httpclient
    .post(api, data, config)
    .then((resp) => {
      rtn.status = resp.status;
      if (resp.status === 200) {
        rtn.data = resp.data.projects;
      } else {
        rtn.error = `api server returned ${resp.status} : ${resp.text}`;
      }
    })
    .catch((err) => {
      rtn = errorParser(err);
    });

  return rtn; // resp.data?.globus_user;
};

export const validateORCIDs = async (orcids, honeycomb) => {
  const tVal = testValue("validateorcids");
  const idstr = orcids.join(",");
  const api = `${JDP_API_ORCID}?ids=${idstr}`;
  let rtn = {};
  const headers = hcHeader(honeycomb);
  await httpclient
    .get(api, { headers })
    .then((resp) => {
      if (tVal === "400") {
        rtn.status = 400;
        rtn.error = "Test for projects/collaborators/ BE return http 400 error";
        return;
      }
      if (tVal === "500") {
        rtn.status = 500;
        rtn.error =
          "Test for  projects/collaborators/ BE return http 500 error";
        return;
      }
      // console.log("[validateORCIDs]", resp);
      rtn.status = resp.status;
      if (resp.status === 200) {
        rtn.data = resp.data;
      } else if (resp.status === 400) {
        rtn.error = `Probably bad ORCID format (${idstr})?`;
      } else {
        rtn.error = `Unknown error (${JSON.stringify(resp)})`;
      }
    })
    .catch((err) => {
      rtn = errorParser(err);
    });

  return rtn;
};

export const globusRequest = async (hcdata, data) => {
  // JDP-2305: use the no-cloudflare download BE
  let rtn = {};
  await httpclient({
    url: JDP_API_FILEDOWNLOAD,
    method: "post",
    baseURL: JDP_NO_CL_API_URL,
    headers: {
      "Content-Type": "application/json",
      ...hcdata,
    },
    data,
  })
    .then((resp) => {
      rtn.data = resp.data;
    })
    .catch((err) => {
      rtn = errorParser(err);
    });

  return rtn;
};

export const getGlobusUser = async (gname = null) => {
  const api = gname
    ? `${JDP_API_GLOBUS_UID}?username=${gname.trim()}`
    : JDP_API_GLOBUS_UNAME;
  let rtn = {};
  await httpclient
    .get(api)
    .then((resp) => {
      if (resp.status === 200) {
        const udata = resp.data;
        if (gname) {
          if (udata?.user_id) {
            rtn.username = gname;
            rtn.user_id = udata.user_id;
          } else {
            rtn.error = `No user_id returned for ${gname} by endpoint ${api}`;
          }
        } else if (udata?.username) {
          rtn.username = udata.username;
        } else {
          rtn.error = `No user info returned by endpoint ${api}`;
        }
      } else {
        rtn.error = `api server returned ${resp.status} : ${resp.text}`;
      }
    })
    .catch((err) => {
      rtn = errorParser(err);
    });

  return rtn; // resp.data?.globus_user;
};

export const restoreFiles = async (hcdata, data) => {
  let rtn = {};
  const config = {
    headers: {
      "Content-Type": "application/json",
      ...hcdata,
    },
  };
  await httpclient
    .post(JDP_API_FILERESTORE, data, config)
    .then((resp) => {
      rtn.data = resp.data;
    })
    .catch((err) => {
      rtn = errorParser(err);
    });

  return rtn;
};

export const restoredFilesAll = async (restoreid, dmax) => {
  // fetch restored files - dmax = total number of dataset
  const api = `request_archived_files/requests/${restoreid}?x=${dmax}&api_version=2`;
  let rtn = {};
  await httpclient
    .get(api)
    .then((resp) => {
      rtn.data = resp.data;
    })
    .catch((err) => {
      rtn = errorParser(err);
    });

  return rtn;
};

export const advancedSearchKeys = async () => {
  const api = JDP_API_ADV_SEARCH_KEYS;
  let rtn = {};

  // console.log(`[httpGrantAccess data]`, data);
  await httpclient
    .get(api)
    .then((resp) => {
      rtn.status = resp.status;
      if (resp.status === 200) {
        rtn = resp.data.fields;
      } else {
        rtn.error = `api server returned ${resp.status} : ${resp.text}`;
      }
    })
    .catch((err) => {
      rtn = errorParser(err);
    });

  return rtn;
};

export default httpclient;
