import React from "react";

import PropTypes from "prop-types";

import CardMui from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import SmsIcon from "@material-ui/icons/Sms";
import PollIcon from "@material-ui/icons/Poll";
import { makeStyles } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Box from "@material-ui/core/Box";

import Button from "../Button/Button";
import classes from "./Card.module.css";
import LinkButton from "./LinkButton";

export const ICON_EVENTS = "ICON_EVENTS";
export const ICON_NEWS = "ICON_NEWS";
export const ICON_NEW_RELEASES = "ICON_NEW_RELEASES";
export const BUTTON_TYPE_LINK = "BUTTON_TYPE_LINK";
export const BUTTON_TYPE_BUTTON = "BUTTON_TYPE_BUTTON";

// Ref : https://material-ui.com/components/cards/#ui-controls
const useStyles = makeStyles(() => ({
  root: {
    minWidth: 275,
    padding: "24px 40px",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 10px 30px 0px #0000001C",
    height: "100%",
    borderRadius: "0px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  content: {
    padding: "0px",
  },
  action: {
    padding: "0px",
  },
}));

const Card = ({ title, content, button, icon, ctype }) => {
  const muiClasses = useStyles();

  let iconComp = null;
  if (icon !== null) {
    switch (icon) {
      case ICON_EVENTS:
        iconComp = <SmsIcon className={classes.cardIconLarge} />;
        break;
      case ICON_NEWS:
        iconComp = <PollIcon className={classes.cardIconLarge} />;
        break;
      case ICON_NEW_RELEASES:
        iconComp = <NewReleasesIcon className={classes.cardIconLarge} />;
        break;
      default:
        <NewReleasesIcon className={classes.cardIcon} />;
    }
  }

  iconComp = iconComp ? <CardMedia>{iconComp}</CardMedia> : null;

  const titleStyle = () => {
    if (ctype === "borderless") {
      return `${classes.h6Desktop} ${classes.heavy} ${classes.smallcardTitle}`;
    }

    return `${classes.h4Desktop} ${classes.heavy} ${classes.cardTitle}`;
  };

  const contentTextStyle = () => {
    return `${classes.bodyBase} ${classes.cardText}`;
  };

  const democardStyle = () => {
    if (ctype === "borderless") {
      return `${muiClasses.root} ${classes.borderless}`;
    }

    return muiClasses.root;
  };

  const getButton = () => {
    // for now borderless cards have no buttons
    if (ctype === "borderless") {
      return null;
    }

    return button ? (
      button.type === BUTTON_TYPE_LINK ? (
        <CardActions className={`${classes.flexRight} muiClasses.action`}>
          <LinkButton
            label={button.label}
            href={button.href}
            newtab={button.newtab}
            honeykey={button.honeykey}
            honeycomb={button.honeycomb}
          />
        </CardActions>
      ) : (
        <CardActions className={classes.cardButton}>
          <Button
            size="large"
            variant="text"
            color="secondary"
            endIcon={<KeyboardArrowRightIcon />}
            onClick={() => {
              if (button.callback) {
                button.callback();
              }
            }}
          >
            {button.label}
          </Button>
        </CardActions>
      )
    ) : null;
  };

  const contentClasses = icon ? classes.withIconContent : muiClasses.content;

  return (
    <CardMui className={democardStyle()}>
      {iconComp}
      <div className={muiClasses.details}>
        <CardContent className={contentClasses}>
          <Typography className={titleStyle()} gutterBottom>
            {title}
          </Typography>
          <Box className={contentTextStyle()}>{content}</Box>
        </CardContent>
        {getButton()}
      </div>
    </CardMui>
  );
};

export default Card;

Card.defaultProps = {
  title: null,
  content: null,
  button: null,
  icon: null,
  ctype: null,
};

Card.propTypes = {
  title: PropTypes.string,
  content: PropTypes.shape({}),
  button: PropTypes.shape({
    type: PropTypes.string,
    label: PropTypes.string,
    callback: PropTypes.func,
    newtab: PropTypes.bool,
    href: PropTypes.string,
    honeykey: PropTypes.string,
    honeycomb: PropTypes.shape(),
  }),
  icon: PropTypes.string,
  ctype: PropTypes.string,
};
