import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  Link,
  TableRow,
  TableCell,
  Box,
} from "@material-ui/core/";

import { useSearchParams } from "../../hooks/useSearchParams";

import classes from "./GenomeTable.module.css";

import SkeletonTable from "./SkeletonTable";
import GenomeRow from "./GenomeRow";

import EmptyFilter from "../UI/EmptyFilter/EmptyFilter";
import NullQueryState from "../UI/NullQueryState/NullQueryState";

import { setLocalStorageRelevant } from "../../utils/LocalStorageUtils";
// import { useFileAndPageContext } from "../../context/FileAndPageContext";

import { selectedForRow } from "../../utils/FileUtils";
import * as cartTool from "../../utils/CartDataTools";

import { ERROR_URL_TOO_LONG } from "../../hooks/useApi";

// special string works for expanded=all on initial page load only.
// useful for kingdom portal referrals where the actual aggregated row.id
// is unknown (e.g. IMG link for AP aggregates on SP)
const EXPAND_ALL = "all";

const GenomeTable = (props) => {
  const {
    selectedFiles,
    setSelectedFiles, // SearchPageContainer.setSelectedFiles
    data,
    honeycomb,
    setRelevantIndex,
    disableRelevancy,
    // ldClient,
    restoreid,
    pageSpec,
    showAll,
    cartData, // from CartPage, by calling BE
  } = props;

  const { searchParams, cart: cartpage } = useSearchParams();

  const history = useHistory();

  const [genomeRows, setGenomeRows] = useState(null); // list of organism groups

  const hcdata = {};
  if (data) {
    [
      "file_total",
      "prioritized_file_total",
      "prioritized_total",
      "total",
      "score_threshold",
    ].forEach((k) => {
      hcdata[k] = data[k];
    });
  }

  // Sync loaded data w/ state to display
  useEffect(() => {
    if (!data && !cartpage) {
      setGenomeRows(null);
      return;
    }

    let pageGenomeRows = [];
    if (cartpage && pageSpec) {
      if (cartData?.organisms) {
        const sIdx = (pageSpec.page - 1) * pageSpec.size;
        pageGenomeRows = cartData.organisms.slice(sIdx, sIdx + pageSpec.size);
      }
    } else if (data?.organisms) {
      const sMin = data.relevant_min_score;
      data.organisms.forEach((org) => {
        if (showAll || org.score.avg >= sMin) {
          pageGenomeRows.push(org);
        }
      });
    }

    setGenomeRows(pageGenomeRows);
  }, [data, pageSpec, showAll, cartpage]);

  // handle the file table HEADER checkbox click event - select/deselect all files in genome dataset
  const handleGenomeLevelCheckboxClicked = (row) => {
    // console.log("[GT : handleGenomeLevelCheckboxClicked]", row);

    let currentSelectedFileIds = selectedForRow(selectedFiles, row.id);
    if ((!restoreid || cartpage) && cartData?.organisms?.length > 0) {
      const cartFileIds = cartTool.fileIdsForOrgId(cartData, row.id);
      if (cartFileIds.length > 0) {
        currentSelectedFileIds = currentSelectedFileIds.concat(cartFileIds);
      }
    }

    const rowFilesNew = [];
    row.files.forEach((file) => {
      if (!currentSelectedFileIds.includes(file._id)) {
        // assume no duplicated file._id in row
        rowFilesNew.push(file);
      }
    });

    if (currentSelectedFileIds.length === row.files.length) {
      // all files of this row has been selected before, deselect this row
      const myArray = selectedFiles.filter(
        (file) => file.organism_id !== row.id
      );

      setSelectedFiles(myArray);
      honeycomb.sendUiInteractionSpan("clicked-unselect-all-genome-checkbox", {
        genome: row.name,
      });
    } else {
      const updatedSelectedFiles =
        selectedFiles?.length > 0
          ? selectedFiles.concat(rowFilesNew)
          : rowFilesNew;
      setSelectedFiles(updatedSelectedFiles);
      honeycomb.sendUiInteractionSpan("clicked-select-all-genome-checkbox", {
        genome: row.name,
      });
    }
  };

  const getExpandedIds = () => {
    const expandedIds = searchParams.get("expanded");
    return expandedIds ? expandedIds.split(",") : [];
  };

  const expandedIdArray = getExpandedIds();

  const rowIsExpanded = (row) => {
    if (expandedIdArray.includes(EXPAND_ALL)) {
      return true;
    }
    if (expandedIdArray.includes(String(row.id))) {
      return true;
    }
    return expandedIdArray.some((id) => String(row.id).includes(id));
  };

  const onRowClick = (event, rowId) => {
    event?.stopPropagation();

    const expandedIds = getExpandedIds().map((id) =>
      id === EXPAND_ALL ? String(rowId) : id
    );

    let updatedExpandedIds = [...expandedIds];

    if (
      updatedExpandedIds.includes(String(rowId)) ||
      updatedExpandedIds.some((id) => String(rowId).includes(id))
    ) {
      honeycomb.sendUiInteractionSpan("collapsed-genome-row");
      // Collapse
      updatedExpandedIds = updatedExpandedIds
        .filter(
          (expandId) =>
            String(expandId) !== String(rowId) &&
            !String(rowId).includes(String(expandId))
        )
        .map((id) => String(id));
    } else {
      // Get the rank of the selected row for Honeycomb metrics
      const rank = genomeRows
        .map((row) => {
          return String(row.id);
        })
        .indexOf(String(rowId));

      honeycomb.sendUiInteractionSpan("expanded-genome-row", {
        result_rank: rank,
      });
      // Expand
      updatedExpandedIds.push(String(rowId));
    }

    // Update search params object
    if (updatedExpandedIds.length > 0) {
      searchParams.set("expanded", updatedExpandedIds);
    } else {
      searchParams.delete("expanded");
    }

    // Update search params in url. adv search failed without convert into plain text?
    history.push(`?${searchParams.toString()}`);
  };

  const emptyMostRelevant = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      onClick={() => {
        const radioIndex = 1;
        setLocalStorageRelevant(radioIndex);
        setRelevantIndex(radioIndex);
        honeycomb.sendUiInteractionSpan("link-in-no-most-relevant", {
          relevancy: { radioIndex, ...hcdata },
        });
      }}
      style={{ cursor: "pointer" }}
    >
      Click here
    </Link>
  );

  // handle error stats of search
  if (data?.error && typeof data?.error === "string") {
    if (data?.error === ERROR_URL_TOO_LONG) {
      return (
        <EmptyFilter
          main="We are not able to execute the query you submitted."
          sub="The maximum URL length is 4094 characters. Please reduce the number of characters in your query (or items in your list) and try again."
        />
      );
    }

    return (
      <EmptyFilter main="Encountered unknown error for your search." sub="" />
    );
  }

  if (!cartpage && !data) {
    return <NullQueryState />;
  }

  if (cartpage && !cartData?.organisms?.length > 0) {
    return (
      <EmptyFilter
        main="Cart is empty"
        sub="Search for datasets/files and start collecting them in the cart"
      />
    );
  }
  if (!cartpage) {
    if (!data || !data.organisms || data.organisms.length === 0) {
      return <EmptyFilter />;
    }
    if (genomeRows?.length === 0 && !disableRelevancy) {
      return (
        <EmptyFilter
          main='No "Most Relevant" Results'
          sub={<div>{emptyMostRelevant} to show &quot;All Results&quot;</div>}
        />
      );
    }
  }

  const topTableStyle = {
    borderTop: cartpage ? "solid 1px #999" : "none",
  };

  return (
    <Paper elevation={0} className={`GenomeTableBox ${classes.paper}`}>
      <TableContainer
        className={classes.tableContainer}
        style={{ borderTop: restoreid && "solid 1px" }}
      >
        <Table
          size="medium"
          aria-label="species table"
          stickyHeader
          className={classes.table}
          data-testid="genome-table"
          style={topTableStyle}
        >
          {!genomeRows ? (
            <SkeletonTable rowCount={cartpage ? 2 : 5} />
          ) : (
            <TableBody>
              {genomeRows.map((row) => {
                const expanded = rowIsExpanded(row);
                return (
                  <GenomeRow
                    data-testid="genome-row-name"
                    key={`${row.kingdom}-${row.id}-row-fragment`}
                    row={row}
                    onRowClick={onRowClick}
                    handleGenomeLevelCheckboxClicked={
                      handleGenomeLevelCheckboxClicked
                    }
                    expanded={expanded}
                    setSelectedFiles={setSelectedFiles}
                    selectedFiles={selectedFiles}
                    cartData={cartData}
                    {...props}
                  />
                );
              })}
              {/* Footer fixed to bottom; add a hidden row so the last data row is above the footer */}
              <TableRow style={{ borderTop: "solid 1px #000" }}>
                <TableCell>
                  <Box
                    style={{
                      width: "auto",
                      marginBottom: 100,
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default GenomeTable;

GenomeTable.defaultProps = {
  data: null,
  selectedFiles: null,
  honeycomb: {
    sendUiInteractionSpan: () => {},
  },
  setRelevantIndex: () => {},
  restoreid: null,
  pageSpec: null,
  showAll: false,
  cartData: null,
};

GenomeTable.propTypes = {
  data: PropTypes.shape(), // TODO: Be more explicit on the shape
  honeycomb: PropTypes.shape({
    sendUiInteractionSpan: PropTypes.func,
  }),
  setRelevantIndex: PropTypes.func,
  disableRelevancy: PropTypes.bool.isRequired,
  // ldClient: PropTypes.shape().isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
  selectedFiles: PropTypes.arrayOf(PropTypes.shape()),
  restoreid: PropTypes.string,
  pageSpec: PropTypes.shape(),
  showAll: PropTypes.bool,
  cartData: PropTypes.shape(),
};
