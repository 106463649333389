/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Link, makeStyles } from "@material-ui/core";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import Card, { BUTTON_TYPE_LINK } from "../Card/Card";
import classes from "./HomePage.module.css";
import {
  HREF_DOC_HOW_TO_SEARCH,
  HREF_DOC_ROOT,
} from "../../utils/URLConstants";
import { Typography } from "../../../node_modules/@material-ui/core/index";

const useStyles = makeStyles(() => ({
  root: {
    padding: "24px 24px",
  },
}));

const dotStyle = {
  width: 12,
  height: 12,
  marginRight: 4,
};

const MiddleCards = ({ honeycomb }) => {
  const muiClasses = useStyles();

  const linkClass = `${classes.bodyBase} ${classes.linkInCard}`;
  const getLinkComp = (label, href) => {
    return (
      <Link
        className={linkClass}
        style={{ marginLeft: 2 }}
        href={href}
        target="_blank"
        onClick={() => {
          if (honeycomb) {
            honeycomb.sendUiInteractionSpan("homepage-link-click", {
              link: `${label}/${href}`,
            });
          }
        }}
      >
        {label}
      </Link>
    );
  };

  const bulletText = (text, colon = false) => (
    <>
      <FiberManualRecordIcon style={dotStyle} />
      <strong>{text}</strong>
      {colon ? ":" : ""}{" "}
    </>
  );

  const bulletStyle = { paddingLeft: 20, paddingTop: 10 };

  const cardSearch = (
    <Box>
      <Box style={{ marginBottom: 20 }}>
        Search metadata for over 13 PB of top-quality plant, algal, fungal, and
        microbial genomic and metagenomic data.
      </Box>
      <Box>Our enhanced search includes:</Box>
      <Box style={bulletStyle}>
        {bulletText("Typeahead")}
        to help you find the organisms or datasets you want.
        <br />
        {bulletText("Robust filters")}
        that will help you narrow in on the datasets or files you’re looking
        for.{" "}
        {getLinkComp(
          "Learn more...",
          "https://sites.google.com/lbl.gov/data-portal-help/home/announcements#h.m7zq40d6e9ty"
        )}{" "}
        <br />
        {bulletText("Advanced search capabilities")}
        that will allow you to construct complex queries across our numerous
        metadata fields.{" "}
        {getLinkComp(
          "Learn more...",
          "https://sites.google.com/lbl.gov/data-portal-help/home/tips_tutorials/search-tips#h.r71fzc9wbcb2"
        )}{" "}
      </Box>
    </Box>
  );

  const cardDownload = (
    <>
      <Typography>
        {`Once you've found the data you need, download it via one of our 3
        methods:`}
      </Typography>
      <Box style={bulletStyle}>
        {bulletText("Browser Download", true)}
        The download will start right in your browser. (Depending on your
        settings) You can set the file name and download location.
        <br />
        {bulletText("Command Line Download", true)}
        Copy the cURL command to download your data via your terminal to either
        your hard-drive or to a network file system.{" "}
        {getLinkComp(
          "Learn more...",
          "https://sites.google.com/lbl.gov/data-portal-help/home/faq?authuser=0#h.7v3sgzjgwl9d"
        )}{" "}
        <br />
        {bulletText("Globus Download", true)}
        Globus is designed to make network interruptions less of a headache for
        you. Use this system for large downloads or for slow (or intermittent)
        connections.{" "}
        {getLinkComp(
          "Learn more...",
          "https://sites.google.com/lbl.gov/data-portal-help/home/faq#h.ip2ohefplxs4"
        )}{" "}
      </Box>
    </>
  );

  const cardAnalyze = (
    <>
      Access JGI’s most popular — and powerful — analysis tools, including{" "}
      {getLinkComp(
        "IMG’s gene search",
        "https://img.jgi.doe.gov/cgi-bin/mer/main.cgi?section=GeneSearch&page=searchForm"
      )}
      ,{" "}
      {getLinkComp(
        "BLAST search",
        "https://img.jgi.doe.gov/cgi-bin/mer/main.cgi?section=FindGenesBlast&page=geneSearchBlast"
      )}
      , and{" "}
      {getLinkComp(
        "function finder",
        "https://img.jgi.doe.gov/cgi-bin/mer/main.cgi?section=FindFunctions&page=findFunctions"
      )}
      ,{" "}
      {getLinkComp(
        "Phytozome’s BLAST search",
        "https://phytozome-next.jgi.doe.gov/blast-search"
      )}{" "}
      and{" "}
      {getLinkComp("BioMart", "https://phytozome-next.jgi.doe.gov/biomart/")},
      and{" "}
      {getLinkComp(
        "MycoCosm/PhycoCosm’s analysis tools",
        "https://mycocosm.jgi.doe.gov/fungi/fungi.info.html"
      )}
      : BLAST search, the annotation finder, MCL clusters, and more.
    </>
  );

  const cardLearn = (
    <>
      Troubleshoot common issues with {getLinkComp("our FAQ", HREF_DOC_ROOT)},
      delve into our{"  "}
      {getLinkComp(
        "Data Usage policy",
        "https://jgi.doe.gov/user-programs/pmo-overview/policies/legacy-data-policies/"
      )}
      , and get more information about{" "}
      {getLinkComp(
        "submitting proposals to JGI",
        "https://proposals.jgi.doe.gov/"
      )}
      .
    </>
  );

  const topRowH = 370;
  const bottomRowH = 260;
  return (
    <>
      <div className={classes.middleCards}>
        <Grid className={muiClasses.root} container spacing={6}>
          <Grid item md={6} xs={12}>
            <Card
              height={topRowH}
              title="Search"
              content={cardSearch}
              button={{
                type: BUTTON_TYPE_LINK,
                href: HREF_DOC_HOW_TO_SEARCH,
                newtab: true,
                label: "Learn more about search",
                honeykey: "homepage-link-click",
                honeycomb,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Card
              height={topRowH}
              title="Download"
              content={cardDownload}
              button={{
                type: BUTTON_TYPE_LINK,
                href: "https://files.jgi.doe.gov/apidoc/",
                newtab: true,
                label: "View our API docs",
                honeykey: "homepage-link-click",
                honeycomb,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Card height={bottomRowH} title="Analyze" content={cardAnalyze} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Card
              height={bottomRowH}
              title="Learn"
              content={cardLearn}
              button={{
                type: BUTTON_TYPE_LINK,
                href: HREF_DOC_ROOT,
                newtab: true,
                label: "Browse the FAQ",
                honeykey: "homepage-link-click",
                honeycomb,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default MiddleCards;

MiddleCards.defaultProps = {
  honeycomb: null,
};

MiddleCards.propTypes = {
  honeycomb: PropTypes.shape(),
};
