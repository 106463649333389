import React, { useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Typography, Link } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@material-ui/core/Box";
import Button from "@mui/material/Button";

import PropTypes from "prop-types";
import classes from "../../UI/InfoBoard/InfoBoard.module.css";

import TapeFileRestoreWarningContent from "./TapeFileRestoreWarningContent";
import { BIT_COPY } from "../../Button/Button";

import Dialog, {
  TT_RED500,
  TT_LAKE500,
} from "../../UI/Dialogs/DraggableDialog/DraggableDialog";

import {
  DF_BTN_ERROR,
  DF_BTN_INFO,
  DF_BTN_ORANGE_BIG,
} from "../../UI/Dialogs/DialogFooter";

import ModalContentBase from "../../UI/InfoBoard/ModalContentBase";
import ConfirmDialog from "../../UI/InfoBoard/Standalone/ConfirmDialog";

import {
  RESTORE_WILL_OVER_USER_QUOTA,
  RESTORE_OVER_USER_QUOTA,
  RESTORE_NEAR_USER_QUOTA,
  RESTORE_DISK_SPACE,
} from "../../Alert/PageAlert/PageAlert";
import { HREF_DOC_DOWNLOAD_DAILY_LIMITS } from "../../../utils/URLConstants";

// import { base as ldbase } from "../../../utils/LDFFUtils";

const TapeFileRestoreDlg = ({
  fetching,
  currentUser,
  closeBtnFn,
  cancelBtnFn,
  confirmBtnFn,
  diskStatus,
  handled,
  requestId,
  data,
  // ldClient,
}) => {
  // console.log("[TapeFileRestoreDlg test]", handled, diskStatus);

  let title = handled
    ? "Download request confirmed"
    : "Confirm download with archived files";

  let btnColor = DF_BTN_INFO;
  let topType = TT_LAKE500;

  let cancelBtnText = cancelBtnFn ? "Refine Cart" : null;
  let acceptBtnText = confirmBtnFn ? "Request all files for download" : null;

  let mainBody = null;

  if (diskStatus?.id === RESTORE_WILL_OVER_USER_QUOTA) {
    return (
      <ConfirmDialog
        title="Your Daily Quota is Exceeded"
        cancelCrossFn={closeBtnFn}
        cancelTxt="Refine Cart"
        cancelBtnFn={closeBtnFn}
      >
        <div>Your daily quota is exceeded by {diskStatus?.value} GB.</div>

        <div style={{ paddingTop: 20 }}>
          Please remove files/datasets from the Cart in order to proceed.
        </div>
      </ConfirmDialog>
    );
  }

  let acceptFn = confirmBtnFn;
  const href = data?.href || "/search";
  const url = requestId
    ? href.indexOf("?") > -1
      ? `${href}&restoreid=${requestId}`
      : `${href}?restoreid=${requestId}`
    : null;

  // JDP-1075
  if (!handled && diskStatus?.id) {
    // not DIALOG_TAPEFILE_RESTORE_HANDLED
    switch (diskStatus.id) {
      case RESTORE_DISK_SPACE:
        title = "Download failed — server error.";
        topType = TT_RED500;
        btnColor = DF_BTN_ERROR;
        cancelBtnText = "Close";
        // console.log(cancelBtnFn, "[TFR]");
        mainBody = (
          <div className={classes.block}>
            <Typography data-testid="zero-disk-dlg-id" paragraph>
              Our servers can&apos;t process your request right now - Please try
              again later.
            </Typography>
            <Typography paragraph>
              If you&apos;ve had several unsuccessful requests, please contact
              JGI for assistance.
            </Typography>
          </div>
        );
        break;
      case RESTORE_OVER_USER_QUOTA:
        title = "Daily download limit exceeded"; // remaining_quota
        btnColor = DF_BTN_ORANGE_BIG;
        acceptBtnText = confirmBtnFn ? "Modify my selection" : null;
        acceptFn = cancelBtnFn;

        mainBody = (
          <div className={classes.block}>
            <Typography data-testid="user-reminder-zero-dlg-id" paragraph>
              You&apos;ve reached your daily download limit for Archived files.
            </Typography>
            <Typography paragraph>
              Please return tomorrow to make your download request again, or
              modify your request to include only files awailable now.
            </Typography>
            <Link
              href={HREF_DOC_DOWNLOAD_DAILY_LIMITS}
              underline="always"
              target="_blank"
            >
              Learn more about download limits.
            </Link>
          </div>
        );
        break;
      case RESTORE_NEAR_USER_QUOTA: // 3.1
        title = "Approaching daily restore limit.";
        btnColor = DF_BTN_ORANGE_BIG;
        acceptBtnText = "Continue";

        mainBody = (
          <div className={classes.block}>
            <Typography paragraph>
              You&apos;re approaching your daily file request limit.
            </Typography>
            <Typography paragraph>
              Selecting additional files may prevent you from completing your
              download request today.
            </Typography>
            <Link
              href={HREF_DOC_DOWNLOAD_DAILY_LIMITS}
              underline="always"
              target="_blank"
            >
              Learn more about download limits.
            </Link>
          </div>
        );
        break;
      default:
        mainBody = null;
    }
  } else if (handled) {
    // DIALOG_TAPEFILE_RESTORE_HANDLED
    acceptBtnText = "OK";
    mainBody = (
      <>
        <div className={classes.block}>
          <Typography paragraph>
            We’ve received your download request and are gathering your files
            now. Your archived files will be accessible within 24 hours.
          </Typography>
          <Typography>
            Please use this{" "}
            <Link
              component={RouterLink}
              target="_blank"
              to={url || "/nowhere"} // nowhere : to make test happy
              data-testid="request-page-link"
              style={{ textDecoration: "underline" }}
            >
              link
            </Link>{" "}
            to check the status of your download request. We’ll send you an
            email to{" "}
            <span style={{ fontWeight: "bold" }}>
              {currentUser.email_address}
            </span>{" "}
            once the files are ready to be downloaded.
          </Typography>
        </div>
      </>
    );
  } else {
    mainBody = <TapeFileRestoreWarningContent cart />;
  }

  const handleAccept = () => {
    // setInRequest(true);
    acceptFn();
  };

  const copyRestoreLinkRef = useRef();

  return (
    <Dialog
      cancelBtnFn={
        handled || diskStatus?.id !== RESTORE_WILL_OVER_USER_QUOTA
          ? cancelBtnFn
          : null
      }
      cancelBtnText={handled ? "Cancel" : cancelBtnText}
      acceptBtnFn={
        diskStatus?.id === RESTORE_DISK_SPACE
          ? null
          : handled
          ? () => {
              copyRestoreLinkRef.current.click();
            }
          : handleAccept
      }
      acceptBtnIcon={handled ? BIT_COPY : null}
      acceptBtnTooltip={handled ? "Link URL copied to clipboard" : null}
      acceptBtnText={handled ? "Copy link to clipboard" : acceptBtnText}
      topType={topType}
      btnColor={btnColor}
    >
      <ModalContentBase
        titleText={title}
        closeFn={closeBtnFn || null}
        subcontent={
          fetching && (
            <Typography style={{ marginTop: "20px" }}>
              <CircularProgress
                size="1.5rem"
                sx={{
                  position: "relative",
                  top: 6,
                  marginRight: "18px",
                }}
              />
              Sending request to server ...{" "}
            </Typography>
          )
        }
      >
        {mainBody}
        {handled && url && (
          <Box>
            <CopyToClipboard
              text={`${window.location.origin}${url}`}
              onCopy={() => {}}
            >
              <Button ref={copyRestoreLinkRef} />
            </CopyToClipboard>
          </Box>
        )}
      </ModalContentBase>
    </Dialog>
  );
};

export default TapeFileRestoreDlg;

TapeFileRestoreDlg.defaultProps = {
  fetching: false,
  cancelBtnFn: null,
  confirmBtnFn: null,
  closeBtnFn: null,
  diskStatus: null,
  handled: false,
  requestId: null,
  ldClient: null,
  data: null,
};

TapeFileRestoreDlg.propTypes = {
  fetching: PropTypes.bool,
  currentUser: PropTypes.shape().isRequired,
  cancelBtnFn: PropTypes.func,
  confirmBtnFn: PropTypes.func,
  closeBtnFn: PropTypes.func,
  diskStatus: PropTypes.shape(),
  handled: PropTypes.bool,
  requestId: PropTypes.number,
  ldClient: PropTypes.shape({
    variation: PropTypes.func,
  }),
  data: PropTypes.shape(),
};
