/* istanbul ignore file */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";

import ReturnLink, { RIGHT_ICON_HELP } from "../TableContainer/ReturnLink";
import LeftDrawer, { MENU_DEFAULT } from "./LeftDrawer";
import RightPanel from "./RightPanel";
import HttpErrorDlg from "./DataGrid/Dialogs/HttpErrorDlg";

import classes from "./MyDataPortal.module.css";
import { myProjects } from "../../utils/HttpClientProvider";

import userTool from "../../utils/UserUtils";

const DLG_HTTP_ERROR = "DLG_HTTP_ERROR";
const MyDataPortal = ({
  href,
  topRect,
  currentUser,
  honeycomb,
  ...restProps
}) => {
  const history = useHistory();

  const [fromHref, setFromHref] = useState(null);
  const [open, setOpen] = useState(true);

  const [menuItem, setMenuItem] = useState(MENU_DEFAULT);

  const [rawData, setRawData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [dialogType, setDialogType] = useState(null);

  const setHTTPError = (message, status) => {
    setDialogType({
      type: DLG_HTTP_ERROR,
      message,
      status,
    });
  };

  useEffect(() => {
    setFromHref(href);
    // to avoid the "Can't perform a React state update on an unmounted component" message in unit test
    // eslint-disable-next-line no-unused-vars
    let isMounted = true;
    const promise = myProjects(
      parseInt(userTool.userId(currentUser), 10),
      honeycomb
    );
    promise
      .then((resp) => {
        if (resp.error) {
          setHTTPError(resp.error, resp.status);
        } else {
          const data = resp.projects;
          if (data.length > 0) {
            setRawData({
              rows: data,
              pi_project_count: resp.pi_project_count,
              non_pi_project_count: resp.non_pi_project_count,
            });
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  /* istanbul ignore next */
  const handleGoBack = () => {
    if (fromHref) {
      window.location.replace(fromHref);
    } else {
      history.goBack();
    }
  };

  const thisRef = useRef(null);
  const [thisHeight, setThisHeight] = useState(200); // default to non-null value

  /* istanbul ignore next */
  const updateContentHeight = () => {
    if (thisRef?.current && topRect) {
      const rect = thisRef.current.getBoundingClientRect();
      setThisHeight(topRect.bottom - rect.top);
    }
  };
  useEffect(() => {
    updateContentHeight();
  }, [thisRef.current, topRect]);

  const showDialog = () => {
    switch (dialogType?.type) {
      case DLG_HTTP_ERROR:
        return (
          <HttpErrorDlg
            message={dialogType.message}
            status={dialogType.status}
            cancelBtnFn={() => {
              setDialogType(null);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box style={{ margin: "0px 120px", minWidth: "800px" }}>
      <Box className={classes.titleBox}>
        <div className={classes.title}>My Data Portal</div>
      </Box>
      <ReturnLink
        leftLabel="Return to the Previous Page"
        leftClick={handleGoBack}
        rightIcon={RIGHT_ICON_HELP}
        rightClick={() => {
          const win = window.open(
            " https://sites.google.com/lbl.gov/data-portal-help/home/tips_tutorials/my-data-portal",
            "_blank"
          );
          win.focus();
        }}
      />

      <Box
        ref={thisRef}
        style={{
          display: "flex",
          position: "relative",
          // minHeight: 200,
          height: thisHeight,
        }}
      >
        <LeftDrawer
          open={open}
          setOpen={setOpen}
          setMenuItem={setMenuItem}
          menu={menuItem}
        />
        <RightPanel
          {...restProps}
          open={open}
          setOpen={setOpen}
          menu={menuItem}
          setMenuItem={setMenuItem}
          topRect={topRect}
          currentUser={currentUser}
          rawData={rawData}
          loading={loading}
          honeycomb={honeycomb}
        />
      </Box>
      {showDialog()}
    </Box>
  );
};

export default MyDataPortal;

MyDataPortal.defaultProps = {
  href: null,
  topRect: null,
  honeycomb: null,
};

MyDataPortal.propTypes = {
  href: PropTypes.string,
  topRect: PropTypes.shape(),
  currentUser: PropTypes.shape().isRequired,
  honeycomb: PropTypes.shape(),
};
