/* istanbul ignore file */
import { useState } from "react";
import { useQuery } from "react-query";

import httpclient, { JDP_API_URL } from "../utils/HttpClientProvider";
import { useConfigContext } from "../context/ConfigContext";
import { useSearchParams } from "./useSearchParams";

import mockdata from "../mocks/jdp1481forTC";
import { debuglog } from "../utils/LDFFUtils";

import { useApiUtil, encodeURIParamsString, URL_MAX_LEN } from "./useApiUtil";

export const ERROR_URL_TOO_LONG = "ERROR_URL_TOO_LONG";

export const useApi = (honeycomb, useMock = false) => {
  const { dataUrl, requiredParams, allowNoParamSearch } = useConfigContext();

  const { searchParams, restoreid } = useSearchParams();
  const { params, searchRec, preProcessData, getEndpoint, extendedURL } =
    useApiUtil();
  const [queryError, setQueryError] = useState(null);

  const hasRequiredParam = () => {
    if (!requiredParams) return false;

    for (let i = 0, len = requiredParams.length; i < len; i++) {
      const requiredParam = requiredParams[i];
      if (searchParams.has(requiredParam)) return true;
    }
    return false;
  };

  const useGenomeQuery = (ldClient = null, customParams = null) => {
    if (
      !restoreid &&
      !searchParams.has("q") &&
      !searchParams.has("since") &&
      !hasRequiredParam() &&
      !allowNoParamSearch
    ) {
      // Dont search if we do not have required params or config allowance
      return {
        data: null,
        isLoading: false,
        isFetchingNextPage: false,
        hasNextPage: false,
      };
    }

    // Use restore requst url if we have a restore id
    const url = restoreid
      ? `/request_archived_files/requests/${restoreid}?api_version=2`
      : dataUrl;

    const endpoint = getEndpoint(url);
    const debug = ldClient ? debuglog(ldClient) : null;
    const endpointFF =
      debug?.endpoint && debug.endpoint[endpoint]
        ? debug.endpoint[endpoint]
        : null;

    // useQuery will run if params and/or url changes
    return useQuery(
      ["allData", params, url],
      async () => {
        const trace = honeycomb.buildTrace(honeycomb.getTraceID());
        const tstart = Date.now();

        const theURL = extendedURL(url, customParams);

        const encodedParams = encodeURIParamsString(params);

        if (encodedParams.length > URL_MAX_LEN) {
          return {
            error: ERROR_URL_TOO_LONG,
            encoded: encodedParams,
            params,
          };
        }

        const res = await httpclient.get(theURL, {
          headers: honeycomb.getTraceHeader(trace),
          params,
        });

        searchRec();

        if (res && res.data) {
          if (
            debug?.endpoint?.any?.raw_json?.log ||
            endpointFF?.raw_json?.log
          ) {
            const dataStr = JSON.stringify(res.data);
            // eslint-disable-next-line
            console.log(
              "[FROM BE]",
              debug?.endpoint?.any.raw_json?.str || endpointFF?.raw_json?.str
                ? dataStr
                : res.data,
              dataStr.length
            );
          }
          const hcPayload = {
            status: res.status,
            duration: tstart ? Date.now() - tstart : 0,
            response: {
              file_total: res.data.file_total,
              total: res.data.total,
            },
            params,
          };
          honeycomb.sendAxiosGetSpan(url, hcPayload, trace);
        }

        if (res.error) {
          setQueryError(res.error);
          return {};
        }
        const rowdata = useMock ? mockdata : res.data;

        const cleanData = Array.isArray(rowdata.organisms)
          ? preProcessData(rowdata)
          : rowdata;

        if (
          debug?.endpoint?.any?.fe_page_json?.log ||
          endpointFF?.fe_page_json?.log
        ) {
          const dataStr = JSON.stringify(cleanData);
          // eslint-disable-next-line
          console.log(
            "[PAGE DATA]",
            debug?.endpoint?.any.fe_page_json?.str ||
              endpointFF.fe_page_json.str
              ? dataStr
              : cleanData,
            dataStr.length
          );
        }

        return cleanData;
      },
      {
        onError: (error) => {
          // github.com/facebook/react/issues/14981#issuecomment-468460187
          if (params.t === "advanced" && error.response) {
            // if from Advanced query and error with response, handle it gracefully
            setQueryError({
              error: error.response.data,
              config: error.response.config,
            });
          } else {
            setQueryError(() => {
              throw new Error(error);
            });
          }
        },
      }
    );
  };

  return {
    useGenomeQuery,
    queryError,
    searchURL: `${JDP_API_URL}${dataUrl}${
      dataUrl?.indexOf("/?") !== -1 ? "&" : "?"
    }${encodeURIParamsString(params)}`,
  };
};

export { useApi as default };
