// split title line into <56 char sublines, on word boundary
const toLinesByWordBoundary = (instr, lmax = 56) => {
  if (!instr) return [];
  const noLineBreakText = instr.replace(/\r?\n|\r/g, " ");
  const toks = noLineBreakText.split(" ").filter((e) => e !== "");
  const lines = [];
  const tmpList = [];
  toks.every((word) => {
    if (tmpList.length > 0 && `${tmpList.join(" ")} ${word}`.length > lmax) {
      lines.push(tmpList.join(" "));
      tmpList.splice(0, tmpList.length);
    }
    tmpList.push(word);
    return true;
  });
  if (tmpList.length > 0) {
    lines.push(tmpList.join(" "));
  }
  return lines;
};

// remove lenth from end, at word boundary
const removeFromEndOnWord = (instr, lenth) => {
  const words = instr.split(" ").filter((w) => w !== "");
  let lastIdx = words.length - 1;
  let cutLen = lenth;
  for (; lastIdx > -1; lastIdx--) {
    const lastWord = words[lastIdx];
    cutLen -= lastWord.length + 1;
    if (cutLen <= 0) break;
  }
  const reminder = words.slice(0, lastIdx);
  return reminder.join(" ");
};

const toInt = (val, defVal = 0) => {
  // parseInt(val), if false, return defVal
  const ival = parseInt(val, 10);
  return !Number.isNaN(ival) ? ival : defVal;
};

// valid ORCID : https://support.orcid.org/hc/en-us/articles/360006897674-Structure-of-the-ORCID-Identifier
// last position can be digit or "X"
const isValidOrcid = (val) =>
  val.match(/^\d{4}-\d{4}-\d{4}-\d{3}[\dX]$/) !== null;

// remove leading AND tailing parenthesis, when both exist
const removeEndParenthesis = (str) => {
  if (str && str.startsWith("(") && str.endsWith(")")) {
    return str.substring(1, str.length - 1);
  }
  return str;
};

// remove leading AND tailing back ticks, when both exist
const removeEndBackTicks = (str) => {
  if (str && str.startsWith("`") && str.endsWith("`")) {
    return str.substring(1, str.length - 1);
  }
  return str;
};

// words is a list of substr in str
const splitByLastWord = (str, words) => {
  let lastIndex = null;
  let separator = null;
  for (let i = 0; i < words.length; i++) {
    separator = words[i];
    lastIndex = str.lastIndexOf(separator);
    if (lastIndex !== -1) {
      break;
    }
  }

  if (!lastIndex || lastIndex === -1) {
    return [str, null, null];
  }

  const head = str.slice(0, lastIndex).trim();
  const tail = str.slice(lastIndex + separator.length).trim();
  return [removeEndParenthesis(head), removeEndParenthesis(tail), separator];
};

export {
  toLinesByWordBoundary,
  removeFromEndOnWord,
  toInt,
  isValidOrcid,
  removeEndParenthesis,
  removeEndBackTicks,
  splitByLastWord,
};
