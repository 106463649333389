/* istanbul ignore file */
import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import PropTypes from "prop-types";
import filesize from "filesize";

import ErrorInfo from "../../UI/ErrorInfo/ErrorInfo";
import JDPHelpIcon from "../../UI/Icons/JDPHelpIcon";
import classes from "../../UI/InfoBoard/InfoBoard.module.css";
import { HREF_DOC_GLOBUS_AND_HOW } from "../../../utils/URLConstants";

const TEXT_FIELD_WIDTH = 318;

const GlobusDownloadUI = ({
  fileSummary,
  currentUser,
  globusUserStatus,
  globusUser,
  handleEdit,
  doGlobusFileTransfer,
  transferRequestError,
  transferInProgress,
}) => {
  // console.log(globusUser, "[GlobusDownloadUI]");
  const sizeInfo = `${filesize(fileSummary.totalSizeInBytes, { round: 1 })}`;

  const globusname = (
    <FormControl
      error={!globusUserStatus.valid || globusUser.name.trim() === ""}
    >
      <OutlinedInput
        data-testid="globus-user-input"
        id="globus-user-input"
        style={{ height: 40, width: TEXT_FIELD_WIDTH }}
        value={globusUser.name}
        autoFocus
        placeholder={currentUser.email_address}
        endAdornment={
          globusUser.initializing ? (
            <InputAdornment position="start">
              <CircularProgress size={20} />
            </InputAdornment>
          ) : null
        }
        onChange={handleEdit}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            doGlobusFileTransfer();
          }
        }}
      />
    </FormControl>
  );

  const invalid = !globusUserStatus.valid ? (
    <ErrorInfo
      id="invalid-globus-user"
      width={TEXT_FIELD_WIDTH}
      message={
        globusUserStatus.message
          ? globusUserStatus.message
          : "Account Name is not found"
      }
    />
  ) : null;

  // console.log("[GD]", currentUser);
  return (
    <div id="globus-download-main" className={classes.block}>
      <Typography paragraph>
        You are going to submit a request to stage {fileSummary.totalCount}{" "}
        files ({sizeInfo}) and share them with your Globus account. Please
        provide your Globus account name in the input field below.
      </Typography>
      <Typography paragraph>
        You will receive an email to{" "}
        <strong>{currentUser.email_address}</strong> when your data is staged
        and ready.
      </Typography>
      <div
        style={{
          marginBottom: 20,
        }}
      >
        <div>
          <Typography
            className={classes.globusAccountNameLabel}
            style={{ marginLeft: 12, marginBottom: 8 }}
          >
            <span style={{ color: "#f00", position: "relative", top: 2 }}>
              *{" "}
            </span>{" "}
            <span
              className={
                !globusUserStatus.valid
                  ? classes.globusAccountNameLabelError
                  : classes.globusAccountNameLabelNormal
              }
            >
              Your Globus account name:
            </span>
          </Typography>
        </div>
        {globusname}
        {invalid}
        <div className={classes.globusAccountNameFootNote}>
          Example jdoe@gmail.com
        </div>
      </div>
      <Typography paragraph>
        <JDPHelpIcon size="default">
          Do not have an account?{" "}
          <Link href={HREF_DOC_GLOBUS_AND_HOW} target="_blank">
            Learn about Globus services here
          </Link>
        </JDPHelpIcon>
      </Typography>
      {transferRequestError && (
        <div
          id="transfer-error-message"
          data-testid="transfer-error-message"
          style={{ border: "solid 1px #f00", padding: 10 }}
        >
          {" "}
          {transferRequestError}{" "}
        </div>
      )}
      {transferInProgress && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </div>
  );
};

export default GlobusDownloadUI;

GlobusDownloadUI.defaultProps = {
  transferRequestError: null,
};

GlobusDownloadUI.propTypes = {
  transferRequestError: PropTypes.string,
  transferInProgress: PropTypes.bool.isRequired,
  globusUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    initializing: PropTypes.bool.isRequired,
  }).isRequired,
  globusUserStatus: PropTypes.shape({
    valid: PropTypes.bool.isRequired,
    message: PropTypes.string,
  }).isRequired,
  fileSummary: PropTypes.shape({
    totalCount: PropTypes.number.isRequired,
    totalSizeInBytes: PropTypes.number.isRequired,
  }).isRequired,
  currentUser: PropTypes.shape({
    email_address: PropTypes.string.isRequired,
  }).isRequired,
  handleEdit: PropTypes.func.isRequired,
  doGlobusFileTransfer: PropTypes.func.isRequired,
};
